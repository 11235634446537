import axios from 'plugins/axios';

const userApi = {
    getUserInfo(params: {appId: string}): Promise<any> {
        return axios.post('/gpbusiness/user/getuserinfo', params);
    },
    setUserStatus(params: {offset: number}): Promise<any> {
        return axios.post('/gpbusiness/user/setUserStatus', params);
    }
};

export default userApi;