import React, {FC} from 'react';
import Styles from './style.module.less';

interface Props {
    iconClassName: string
};

const IconCustom: FC<Props> = ({iconClassName}) => {
    return (
        <span className={Styles[iconClassName]}></span>
    );
};

export default IconCustom;