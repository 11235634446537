import profileRoute from 'pages/Profile/menu';
import UniversialGameRoute from 'pages/UniversialGame/menu';
import agentOperator from 'pages/AgentOperator/menu';
import agentRoute from 'pages/AgentRoute/menu';
import guildRoute from 'pages/Guild/menu';

export interface MenuBase {
    key: string,
    title: string,
    icon?: string,
    auth?: string,
    needLogin?: boolean,
    needCertification?: boolean,
    newCp?: boolean,
    iconCustom?: string
};

export interface MenuWithComponent extends MenuBase {
    path: string,
    activeMenu?: string [],
    params?: string [],
    component: string,
    nav?: boolean
};

export interface MenuWithSubs extends MenuBase {
    subs: GPMenu []
};

export interface MenuWithRedirect extends MenuBase {
    redirect: string
};

export type GPMenu = MenuWithComponent | MenuWithSubs | MenuWithRedirect;

export type HeaderMenu = GPMenu & {
    defaultPath: string | ((auth: {[index: string]: boolean}) => string),
    collapsible?: boolean,
    nav?: boolean,
    subs?: GPMenu []
}
export interface RouteConfig {
    menus: HeaderMenu [],
    other?: GPMenu [],
    [index: string]: any;
};

const routeConfig: RouteConfig = {
    menus: [
        profileRoute,
        UniversialGameRoute,
        agentOperator,
        agentRoute,
        guildRoute,
        {
            key: 'message',
            title: '消息中心',
            auth: 'AUTH_XXZX',
            defaultPath: '/message',
            needLogin: true,
            needCertification: true,
            subs: [
                {
                    key: 'message',
                    path: '/message',
                    title: '消息中心',
                    component: 'MessageCenter',
                    auth: 'AUTH_XXZX',
                    needLogin: true,
                    needCertification: true
                }
            ]
        }
    ],
    other: [
        {
            key: 'index',
            path: '/index',
            title: '首页',
            component: 'Index'
        },
        {
            key: 'doc',
            path: '/doc',
            title: '帮助中心',
            params: ['id'],
            component: 'ADemo'
        },
        {
            key: 'brand',
            path: '/brand',
            title: '品牌授权',
            component: 'BrandAuthorization',
            needLogin: true,
            needCertification: false
        },
        {
            key: 'certificationUniversial',
            path: '/certification',
            title: '账号认证',
            component: 'UniversialCertification',
            needLogin: true,
            needCertification: false
        },
        {
            key: 'supplyInfoSubmit',
            path: '/supplyInfoSubmit',
            title: '账号认证',
            component: 'SupplyInfoSubmit',
            needLogin: true,
            needCertification: false
        },
        {
            key: 'cooperationMode',
            path: '/cooperationMode/detail',
            title: '合作模式',
            params: ['appId'],
            component: 'UniversialCooperationMode'
        },
        {
            key: 'cooperationModeTransport',
            path: '/cooperationMode/transport',
            title: '联运合作模式',
            params: ['appId', 'stepNum'],
            component: 'UniversialCooperationTransport'
        },
        {
            key: 'cooperationModeBrand',
            path: '/cooperationMode/brand',
            title: '品牌合作模式',
            params: ['appId'],
            component: 'UniversialCooperationBrand'
        }
    ]
};

export default routeConfig;