import React from 'react';
import {Layout} from 'antd';
import Header from '../components/Header';
import styles from './style.module.less';

const PublicLayout: React.FC = ({children}) => {
    return (
        <Layout style={{minHeight: '100%'}}>
            <Header />
            <Layout.Content>
                <div className={styles.content}>
                    {children}
                </div>
            </Layout.Content>
        </Layout>
    );
};

export default PublicLayout;