import React from 'react';
import {useHistory} from 'react-router-dom';
import {AutoComplete, Input, Spin} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import {useDebounceFn} from '@baidu/gp-hooks';
import styles from './style.module.less';
import dompurify from 'dompurify';

interface Props {
    doc: {id: string, title: string, children: any[]}[]
}

const SearchInput: React.FC<Props> = ({doc}) => {
    const history = useHistory();

    const [value, setValue] = React.useState<string>();
    const [query, setQuery] = React.useState<string>();
    const [options, setOptions] = React.useState<any []>([]);
    const [loading, setLoading] = React.useState<boolean>(false);

    const handleSearch = useDebounceFn(async (query: string) => {
        query = query.trim();
        setQuery(query);
        if (!query) {
            setOptions([]);
            return;
        }
        setLoading(true);
        const result = doc.reduce((arr: any[], menu) => {
            const res = menu.children
                .filter(item => item.title.includes(query))
                .map(item => ({
                    ...item,
                    title: item.title.replace(
                        query,
                        `<span style="display: inline-block; 
                        color: red; padding: 0 4px; background: #00000011">${query}</span>`
                    )
                }));
            if (res.length === 0) return [...arr];
            return [
                ...arr,
                {
                    ...menu,
                    children: res
                }
            ];
        }, []);

        setLoading(false);

        setOptions(
            result.map((item) => ({
                label: item.title,
                options: item.children.map((i: any) => ({
                    value: `/doc/${i.id}`,
                    label: (
                        <div dangerouslySetInnerHTML={{__html: dompurify.sanitize(i.title)}} />
                    )
                }))
            })));
    }, {wait: 500});

    return (
        <div className={styles.searchInput}>
            <AutoComplete
                style={{width: '100%'}}
                dropdownMatchSelectWidth={options.length > 0 ? 500 : true}
                value={value}
                options={options}
                onSearch={handleSearch.run}
                onSelect={value => {
                    history.push(value);
                    setValue(query);
                }}
                notFoundContent={loading
                    ? (
                        <Spin size="small">
                            <div style={{width: '100%', height: 24}} />
                        </Spin>
                    )
                    : !!query
                        ? '无搜索结果'
                        : null
                }
            >
                <Input
                    allowClear
                    suffix={<SearchOutlined style={{fontSize: '16px', color: '#9c9c9c'}} />}
                    placeholder="在站内搜索"
                    onChange={e => setValue(e.target.value)}
                />
            </AutoComplete>
        </div>
    );
};

export default SearchInput;