import axios from 'plugins/axios';

const messageApi = {
    getUnreadMsgCount(): Promise<UnreadMsgCount> {
        return axios.post('/gpbusiness/msg/getUnreadMsgCount');
    },
    getMsgList(params: GetMsgListParams): Promise<{count: number, listData: Msg[]}> {
        return axios.post('/gpbusiness/msg/getMsgList', params);
    },
    updateMsgInfo(params: UpdateMsgInfoParams): Promise<any> {
        return axios.post('/gpbusiness/msg/updateMsgInfo', params);
    },
    getSysWindow(): Promise<any> {
        return axios.post('/gpbusiness/msg/getSysWindow');
    },
    getNotification(): Promise<any> {
        return axios.post('/gpbusiness/msg/getNotification');
    },
    getPersonNotification(params: GetPersonNotificationParams): Promise<any> {
        return axios.post('/gpbusiness/msg/getPersonNotification', params);
    }
};

interface GetMsgListParams {
    type: string
    page: number
    size: number
    title: string
};

interface UpdateMsgInfoParams {
    id: string
    type: string
};

interface UnreadMsgCount {
    sysNotice: number,
    gameNotice: number,
    activeNotice: number
};

export interface Msg {
    isRead: number
    id: string
    title: string
    content: string
};

interface GetPersonNotificationParams {
    userSource: number
}

export default messageApi;