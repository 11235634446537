export const REG_IP = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
export const REG_PKGNAME = /^com\.[a-zA-Z0-9]+\.[a-zA-Z0-9]+\.g\.baidu$/;
export const REG_URL = /^https?:\/\/([\w-]+-?\.)+[\w-]+(:\d+)?(\/#)?(\/[\w-,./?%&=\u4e00-\u9fa5]*)?/;
export const REG_MOBILE = /^1[3456789]\d{9}$/;
export const REG_TELEPHONE = /^0\d{2,3}-\d{7,8}$/;
export const REG_EMAIL = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
export const REG_QQ = /^[1-9][0-9]{4,10}$/;
// eslint-disable-next-line max-len
export const REG_IDCARD = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/;
export const REG_CHINESE = /[\u4e00-\u9fa5]/g;
export const REG_TEL = /^400[0-9]{7}|^800[0-9]{7}|^1[3456789]\d{9}$|^0\d{2,3}-\d{7,8}/;
export const REG_ENGNUMSYMBOL = /^[a-z_A-Z0-9-.!@#$%\\^&*)(+={}[\]/",'<>~·`?:;|\s]+$/;
export const REG_IMGANDA = /<img [^>]*src=['"]([^'"]+)[^>]*>|<a [^>]*href=['"]([^'"]+)[^>]*/g;
export const REG_COMPANY_NAME = /^[\u4e00-\u9fa5()（）《》]+$/g;
export const REG_SPECIAL_CHAR = /[/<>%$^]+/g;
export const REG_FAREN_SHOUQUAN_NAME = /^[\u4e00-\u9fa5·]+$/g;
export const REG_ID_CARD = /[1-9]\d{5}(19|20)\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9X]/;

export const LANGUAGE = {
    1: '中文',
    2: '非中文'
};

export const GAME_GENRES = {
    1: 'RPG',
    2: 'ARPG',
    3: '卡牌',
    4: '格斗',
    5: 'TCG',
    6: '塔防',
    7: '动作',
    8: '冒险',
    9: '经营',
    10: '策略',
    11: '飞行',
    12: '射击',
    13: '养成',
    14: '音乐',
    15: '舞蹈',
    16: '跑酷',
    17: '棋牌',
    18: '体育',
    19: '竞速',
    20: '赌博',
    21: '休闲',
    22: '益智'
};

export const GAME_ENGINE = {
    1: 'Android 原生',
    2: 'Cocos2d_X',
    3: 'Flash',
    4: 'Unity3D',
    5: 'C++',
    6: 'Html',
    7: 'C',
    8: 'Libgdx',
    9: 'Openglex',
    10: '其他'
};

export const DIMENSION = {
    2: '2D',
    3: '2.5D',
    4: '3D'
};

export const PICTURE_STYLE = {
    2: '写实',
    3: '白描',
    4: '二次元',
    5: '像素',
    6: '玄幻',
    7: '其他'
};

export const THEME = {
    2: '武侠',
    3: '魔幻',
    4: '仙侠',
    5: '动漫',
    6: '电影',
    7: '小说',
    8: '现实',
    9: '历史',
    10: '惊悚',
    11: '其他'
};

export const INTELLECTUAL_PROPERTY = {
    2: '是',
    3: '否'
};

export const COOP_MODE = {
    // 1: '广告',
    2: '内购'
};

export const SINGLE_GAME_GENRES = {
    1: 'RPG',
    2: 'ARPG',
    3: '卡牌',
    4: '格斗',
    5: 'TCG',
    6: '塔防',
    7: '动作',
    8: '冒险',
    9: '经营',
    10: '策略',
    11: '飞行',
    12: '射击',
    13: '养成',
    14: '音乐',
    15: '舞蹈',
    16: '跑酷',
    17: '棋牌',
    18: '体育',
    19: '竞速',
    20: '赌博',
    21: '休闲',
    22: '益智'
};

export const BILLING_TYPE = {
    1: '道具计费',
    2: '免费试玩'
};

export const BILLING_METHOD_CLASSIFY = {
    1: '运营商派生计费',
    2: 'CP 方计费',
    3: '百度计费',
    4: '其他计费'
};

export const BILLING_METHOD = {
    1: '移动',
    2: '联通',
    3: '电信',
    4: '第三方',
    5: '移动 MM',
    6: '移动基站'
};

export const SOLE_AGENT = {
    1: '是',
    2: '否'
};

export const CONTRACT_UNIVERSAL_STATUS = {
    1: {
        message: '开发者您好，您还未完善财务资质信息，请先完善相关信息并审核通过。',
        url: '/profile/finance'
    },
    2: {
        message: '开发者您好，您的财务资质信息审核未能通过，请联系商务同学解决。',
        url: '/profile/finance'
    },
    3: {
        message: '开发者您好，您还未完善财务资质信息，请先完善相关信息并审核通过。',
        url: '/profile/finance'
    },
    4: {
        message: '请先签署完成百度联运游戏合同，再提交游戏包，感谢你的支持。',
        url: '/cooperationMode/transport/{APPID}/1'
    },
    5: {
        message: '您的合同已到期，请先重新签署完成百度联运游戏合同，再提交游戏包，感谢你的支持。',
        url: '/universialGame/{SDK_SOURCE}/gameContractDetail/{CONTRACT_STATUS}/{APPID}/{CONTRACT_ID}'
    },
    6: {
        message: '当前签署合同数据异常，请联系对接商务同学，感谢你的支持。',
        url: '/universialGame/{SDK_SOURCE}/gameContractList'
    }
};

export const AUDIT_STATUS = {
    1: {
        label: '待提交游戏包',
        value: 'warning'
    },
    2: {
        label: '待审核',
        value: 'warning'
    },
    3: {
        label: '审核中',
        value: 'processing'
    },
    4: {
        label: '审核未通过',
        value: 'error'
    },
    5: {
        label: '已上线',
        value: 'success'
    },
    6: {
        label: '已下线',
        value: 'default'
    }
};

export const SDK_TYPE = {
    1: '3.0版本以下',
    2: '3.0版本以上',
    0: '其他'
};

export const PLATFORM_TYPE = {
    0: '安卓',
    1: 'iOS'
};

export const BAIDU_BILL_STATUS = {
    // 0: '已提现',
    2: '未确认',
    3: '已确认',
    4: '质疑中'
};

export const BAIDU_ACCOUNT_STATUS = {
    0: '财务已付款',
    3: '待提现',
    6: '待寄送发票与对账单',
    7: '财务审核中',
    8: '财务驳回',
    9: '财务付款失败',
    11: '待上传发票'
};
export const CONTRACT_SIGN_STATUS = {
    0: '未签约',
    1: '签约中',
    2: '已签约'
};

export const BAIDU_CONTRACT_SIGN_STATUS = {
    0: '未签约',
    1: '签约中',
    2: '已签约',
    9: '改签中'
};

export const CONTRACT_AUDIT_STATUS = {
    0: '未审核',
    1: '已审核',
    2: '已拒绝'
};

export const CONTRACT_VALID_STATUS = {
    0: '生效中',
    1: '未生效',
    2: '已终止',
    3: '已作废',
    4: '已盖章'
};

export const CONTRACT_TYPE = {
    0: '范本合同',
    1: '非范本合同'
};

export const CONTRACT_OPT_TYPE = {
    CHECK: 0,
    SIGN: 1,
    RENEW: 2,
    RESIGN: 3
};

// 合同类别
export const GAME_STATUS = {
    0: '手游',
    1: '页游'
};

// 超品状态
export const BRAND_PRO_STATUS = {
    1: '暂未开通',
    2: '已开通',
    3: '即将到期',
    4: '已过期'
};

// eslint-disable-next-line max-len
export const DEFAUT_PASS_PORTRAIT = 'https://gameplus-platform.cdn.bcebos.com/gameplus-platform/upload/file/source/6d71025b84bb0a1b729fb400c66442f4.png';

// 营业期限示例图
// eslint-disable-next-line max-len
export const businessTermTimeSample0 = 'https://fenwan.cdn.bcebos.com/cms/gamenow/webgame/2023-7/1688986712663/bbbe338025b8.png';
// eslint-disable-next-line max-len
export const businessTermTimeSample1 = 'https://fenwan.cdn.bcebos.com/cms/gamenow/webgame/2023-7/1688986713204/f399878847fb.png';


// 财务-收银台-账单状态
export const BAIDU_CASHIER_BILL_STATUS = {
    1: '未确认',
    2: '已确认',
    3: '已提现',
    4: '付款失败'
};

// 财务-收银台-提现状态
export const BAIDU_CASHIER_WITHDRAW_STATUS = {
    1: '提现中',
    2: '提现成功',
    3: '提现失败'
};