import React, {Fragment, useContext, useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Button, Layout, Menu, Space, Dropdown, Badge, Tooltip, Avatar} from 'antd';
import config from 'config';
import UserContext from 'context/UserContext';
import MessageContext from 'context/MessageContext';
import IconFont from 'components/IconFont';
import styles from './style.module.less';
import GameInfoContext from 'context/GameInfoContext';
import useAuth from 'hooks/useAuth';

declare const window: any;

interface Props {
    activeMenu?: string
};

const menuList = [{
    name: '系统公告',
    key: 1,
    value: 'sysNotice'
}, {
    name: '站内信',
    key: 2,
    value: 'gameNotice'
}, {
    name: '活动公告',
    key: 3,
    value: 'activeNotice'
}];

const Header: React.FC<Props> = () => {
    const {userInfo, hasLogin, statusInfo, isDriver} = React.useContext(UserContext);
    const {setAppId} = useContext(GameInfoContext);
    const [showSdkPlat, setShowSdkPlat] = useState(false);
    const hasCertification = !!(statusInfo?.hasCertification);
    const isFirstCertificate = !!(statusInfo?.isFirst);
    const hasCertificeAuth = useAuth('AUTH_RZXXGL');
    const hasBrandAuth = useAuth('AUTH_PPXX');
    const hasFinanceAuth = useAuth('AUTH_CWZZGL');
    const hasMessageAuth = useAuth('AUTH_XXZX');
    const hasTeamAuth = useAuth('AUTH_TDZHGL');

    const history = useHistory();

    const pageUrl = window.location.href;

    const logout = () => {
        setAppId('');
        // eslint-disable-next-line max-len
        window.location.href = `https://passport.baidu.com/?logout&tpl=baidugame&u=${encodeURIComponent(window.location.href)}`;
    };

    const register = () => {
        // eslint-disable-next-line max-len
        window.location.href = `https://passport.baidu.com/v2/?reg&tpl=baidugame&u=${encodeURIComponent(window.location.href)}`;
    };

    const goSDKplat = () => {
        window.open('https://sdk.caict.ac.cn');
    };

    useEffect(() => {
        if (pageUrl.indexOf('/universialGame/transportManage?tabKey=2') !== -1) {
            setShowSdkPlat(true);
        }
        else {
            setShowSdkPlat(false);
        }
    }, [pageUrl]);

    const {data} = React.useContext(MessageContext);
    const {sysNotice, gameNotice, activeNotice} = data;

    return (
        <Layout.Header className={styles.header}>
            <div className={styles.headerWrapper}>
                <div className={styles.logo} onClick={() => history.push('/index')}>
                    <img src={config.logo} alt={config.siteName} />
                </div>
                <Space size={8}>
                    {
                        (
                            <>
                                {
                                    showSdkPlat && <div
                                        className={styles.sdkPlat}
                                        onClick={goSDKplat}>全国SDK管理服务平台</div>
                                }
                                {
                                    ((userInfo?.newIndexFirst === 1 && isDriver === true)) ? (
                                        <div id='helpContent' style={{display: 'flex'}}>
                                            <IconFont
                                                name="ic_help"
                                                style={{fontSize: 18, color: '#9c9c9c'}}
                                            />
                                        </div>
                                    ) : (
                                        <Tooltip placement="bottom" title="帮助中心">
                                            <Link id='helpContent' to='/doc/3' style={{display: 'flex'}}>
                                                <IconFont
                                                    name="ic_help"
                                                    style={{fontSize: 18, color: '#9c9c9c'}}
                                                />
                                            </Link>
                                        </Tooltip>
                                    )
                                }
                            </>
                        )
                    }
                    {hasLogin && (
                        <React.Fragment>
                            {hasCertification && hasMessageAuth && <Dropdown
                                disabled={userInfo?.newIndexFirst === 1 && isDriver === true}
                                overlay={
                                    <Menu>
                                        {menuList.map(item => (
                                            <Menu.Item key={item.key} className={styles.msgItem}>
                                                <Link to={`/message?type=${item.key}`}>
                                                    <Space size={40}>
                                                        {item.name}
                                                        <Badge count={data[item.value]} />
                                                    </Space>
                                                </Link>
                                            </Menu.Item>
                                        ))}
                                    </Menu>
                                }
                                placement="bottomCenter"
                            >
                                <div className={styles.headerAction}>
                                    <Badge
                                        count={sysNotice + gameNotice + activeNotice}
                                        size="small"
                                        className={styles.msgBadge}
                                    >
                                        <IconFont name="ic_message" style={{fontSize: 18, color: '#9C9C9C'}}/>
                                    </Badge>
                                </div>
                            </Dropdown>}
                            <Dropdown
                                disabled={userInfo?.newIndexFirst === 1 && isDriver === true}
                                placement="bottomRight"
                                overlay={
                                    <Menu theme="light" style={{width: 120}}>
                                        {(
                                            hasCertification || (
                                                !hasCertification
                                                && !isFirstCertificate
                                                && statusInfo?.brandStatus !== -1
                                            )
                                        ) && hasCertificeAuth && (
                                            <Menu.Item><Link to='/profile/certification'>认证信息</Link></Menu.Item>
                                        )}
                                        {hasCertification && (
                                            <Fragment>
                                                {userInfo?.userSource !== 5 && hasBrandAuth
                                                    && <Menu.Item><Link to='/profile/brand'>企业品牌信息</Link></Menu.Item>
                                                }
                                                {hasFinanceAuth
                                                    && <Menu.Item><Link to='/profile/finance'>财务信息</Link></Menu.Item>}
                                                {userInfo?.userSource === 5 && hasTeamAuth
                                                    && <Menu.Item><Link to='/profile/team'>团队账号</Link></Menu.Item>
                                                }
                                            </Fragment>
                                        )}
                                        <Menu.Item onClick={logout}>退出登录</Menu.Item>
                                    </Menu>
                                }
                            >
                                <div id="userInfo" className={styles.headerAction}>
                                    <Space size={7} className={styles.userInfo}>
                                        <Avatar shape="circle" src={userInfo?.passPortrait}/>
                                        <span style={{padding: '0 5px'}}>{userInfo?.username}</span>
                                        <IconFont name="arrow_down" style={{fontSize: 10}}/>
                                    </Space>
                                </div>
                            </Dropdown>
                        </React.Fragment>
                    )}
                    {!hasLogin && (
                        <React.Fragment>
                            <div
                                style={{marginRight: 20}}
                                className={styles.headerAction}
                                onClick={() => {
                                    window.loginInstance.show();
                                }}
                            >登录</div>
                            <Button type="primary" style={{width: 76}} onClick={register}>注册</Button>
                        </React.Fragment>
                    )}
                </Space>
            </div>
        </Layout.Header>
    );
};

export default Header;