import React from 'react';
import iconJson from './icon.json';

interface Props {
    name: string,
    style?: React.CSSProperties
}

const IconFont: React.FC<Props> = ({name, style}) => {
    const filterIcon = ['ic_addDotted', 'ic_brand', 'ic_brandFree', 'ic_authorization'];
    let iconHtml: string = '';
    if (filterIcon.includes(name)) {
        iconHtml = iconJson.data.find(item => item.name === name)?.svg
            .replace(/width=".*?"/, 'width="1em"')
            .replace(/height=".*?"/, 'height="1em"')
            ?? '';
    } else {
        iconHtml = iconJson.data.find(item => item.name === name)?.svg
            .replace(/width=".*?"/, 'width="1em"')
            .replace(/height=".*?"/, 'height="1em"')
            .replace(/id="[a-z]+/g, `id=${name}`)
            .replace(/xlink:href="#[a-z]+/g, `xlink:href=#${name}`)
            ?? '';
    }

    return (
        <span
            style={style}
            className="anticon"
            dangerouslySetInnerHTML={{__html: iconHtml}}
        />
    );
};

export default IconFont;