import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter as Router} from 'react-router-dom';
import {ConfigProvider} from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import 'moment/locale/zh-cn';
import BaseLayout from 'layout/BaseLayout';
import {UserProvider} from 'context/UserContext';
import {MessageProvider} from 'context/MessageContext';
import {GameInfoProvider} from 'context/GameInfoContext';
import 'style/driver.less';
import './index.less';


ReactDOM.render(
    <ConfigProvider locale={zhCN}>
        <Router>
            <UserProvider>
                <MessageProvider>
                    <GameInfoProvider>
                        <BaseLayout />
                    </GameInfoProvider>
                </MessageProvider>
            </UserProvider>
        </Router>
    </ConfigProvider>,
    document.getElementById('root')
);
