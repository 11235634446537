import {HeaderMenu} from 'routes/universialConfig';

const menu: HeaderMenu = {
    key: 'agentRoute',
    title: '合同签署',
    defaultPath: '/agentRoute',
    needLogin: true,
    subs: [
        {
            key: 'agentRouteHome',
            title: '首页',
            auth: 'AUTH_NRHZSZY',
            component: 'AgentRouteHome',
            path: '/agentRoute/home',
            icon: 'ic_gamehomepage'
        },
        {
            key: 'contractManage',
            title: '合同管理',
            icon: 'ic_contractmanagement',
            subs: [
                {
                    key: 'contractList',
                    title: '内容业务合同',
                    auth: 'AUTH_NRYWHT',
                    component: 'AgentRouteContractList',
                    path: '/agentRoute/contractList'
                },
                {
                    key: 'contractList',
                    title: '合同详情',
                    component: 'AgentRouteBussinessContract',
                    path: '/agentRoute/bussinessContract',
                    nav: false,
                    params: ['opt', 'id']
                }
            ]
        },
        {
            key: 'accountMemberManage',
            title: '权限管理',
            auth: 'AUTH_YZSHPGL',
            iconCustom: 'icon-account-member-manage',
            path: '/agentRoute/accountMemberManage',
            component: 'AccountMemberManage' // 三个身份公用组件
        }
    ]
};

export default menu;