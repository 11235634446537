import React, {useState, useContext, useEffect, useMemo, Fragment} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {Button, Layout, Menu, Tooltip, Image} from 'antd';
import {PlusOutlined, LeftOutlined} from '@ant-design/icons';
import classnames from 'classnames';
import {useMount} from '@baidu/gp-hooks';
import usePrevious from 'hooks/usePrevious';
import {GPMenu, MenuWithComponent} from 'routes/universialConfig';
import UserContext, {UserInfo} from 'context/UserContext';
import GameInfoContext from 'context/GameInfoContext';
import IconFont from 'components/IconFont';
import Header from '../components/Header';
import GameSwitch from './components/GameSwitch';
import styles from './style.module.less';
import ProNewIcon from 'assets/brandSuper/new.png';
import IconCustom from './components/IconCustom';

const {SubMenu} = Menu;
const {Sider, Content} = Layout;

const getMenuAfterIcon = (menu: GPMenu, modeStatus: any) => {
    if((['brandPro', 'postBar'].indexOf(menu.key) > -1 && modeStatus?.brandProStatus === 1)
        || ['proIntroduce', 'postTBarIntro', 'postBar'].indexOf(menu.key) > -1
    ) {
        return (
            <span className={styles.menuIcon}>
                <Image
                    src={ProNewIcon}
                    preview={false}
                    width={37}
                    height={14}
                    // style={{position: 'absolute', top: '50%', transform: 'translateY(-50%)', marginLeft: 2}}
                />
            </span>
        );
    }
};

const iterMenu = (
    menu: GPMenu,
    hasCertification: boolean,
    hasAgent: boolean,
    userInfo: UserInfo | undefined,
    isDriver: boolean,
    modeStatus: any,
    hasGuild: boolean
) => {
    if(menu.key === 'brand' && (hasAgent || modeStatus?.isBrandProMode || hasGuild)) {
        return undefined;
    }
    return 'subs' in menu ? (
        <SubMenu
            className={menu.key}
            key={menu.key}
            disabled={userInfo?.newGameFirst === 1 && isDriver === true}
            title={
                <span id={menu.title === '基础管理' ? 'universialGame' : ''}>{menu.title}</span>
            }
            icon={menu.icon && <IconFont name={menu.icon} style={{fontSize: '18px'}}/>}
        >
            {menu.subs.map(menu => iterMenu(
                menu, hasCertification, hasAgent, userInfo, isDriver, modeStatus, hasGuild))}
        </SubMenu>
    ) : (
        ('redirect' in menu || menu.nav !== false)
        && (!menu.needCertification || (menu.needCertification && hasCertification))
        && (
            <Menu.Item
                key={menu.key}
                icon={
                    menu.iconCustom
                        ? <IconCustom iconClassName={menu.iconCustom}/>
                        : menu.icon && <IconFont name={menu.icon} style={{fontSize: '18px'}}/>
                }
            >
                {'redirect' in menu
                    ? <a href={`${menu.redirect}`} className={styles.aHover}>
                        <span>{menu.title}</span>
                        {getMenuAfterIcon(menu, modeStatus)}
                    </a>
                    : <Link to={`${menu.path}`} className={styles.aHover}>
                        <span>{menu.title}</span>
                        {getMenuAfterIcon(menu, modeStatus)}
                    </Link>
                }
            </Menu.Item>
        )
    );
};

const SiderFLayout: React.FC = ({children}) => {
    const {pathname} = useLocation();
    const history = useHistory();
    const {statusInfo, userInfo, isDriver} = useContext(UserContext);
    const {appId, setAppId, universialAppList = [], modeStatus} = useContext(GameInfoContext);
    const hasCertification = !!statusInfo?.hasCertification;
    const [gameName, setGameName] = useState<string>();

    // const hasGame = !!universialAppList.length || gameName;
    const hasAgent = userInfo?.userSource === 5;
    const hasGuild = userInfo?.userSource === 6;
    const hasBrandProGame = universialAppList.some(app => app.brandProStatus !== 1);

    const {
        activeMenu, subMenus, selectedKeys, collapsible, openKeys
    } = useNavigation(pathname);

    const [collapsed, setCollapsed] = useState<boolean>(false);
    const [_openKeys, setOpenKeys] = useState<string []>(selectedKeys);
    const prevMenu = usePrevious(activeMenu);

    const accountPathList = [
        '/universialGame/home',
        '/universialGame/baiduFinance',
        '/universialGame/baiduDetail',
        '/universialGame/baiduCashierFinance',
        '/universialGame/baiduCashierDetail',
        '/universialGame/invoice',
        '/universialGame/duokuFinance',
        '/universialGame/duokuDetail',
        '/universialGame/baidu/contractList',
        '/universialGame/baidu/contractDetail',
        '/universialGame/duoku/contractList',
        '/universialGame/duoku/contractDetail',
        '/universialGame/proIntroduce',
        '/universialGame/postTBarIntro', // 贴吧
        '/universialGame/accountMemberManage' // 权限管理
    ];
    const accountKeyList = useMemo(() => ['home', 'finance', 'proIntroduce',
        'universalFinance', 'contract', 'brand', 'certification', 'postTBarIntro', 'accountMemberManage'], []);
    const [menuKeyList, setMenuKeyList] = useState<string[]>(accountKeyList);

    // 是否是账号维度的菜单
    const isAccountMenu = !!(accountPathList.find(item => pathname.indexOf(item) > -1));

    const [_selectedKeys, setSelectedKeys] = useState<string[]>(selectedKeys);

    useEffect(() => {
        if (!modeStatus) {
            return;
        }
        const {isBrandMode, isJointMode} = modeStatus;
        // eslint-disable-next-line max-len
        const defaultGameKeyList = ['gameHome', 'universalGameManage', 'brand', 'certification', 'finance'];
        const jointModes = !!isJointMode ? ['transport', 'gameContract'] : [];
        const gameKeyList = [
            ...defaultGameKeyList,
            !!isBrandMode ? 'brandManage' : '',
            ...jointModes
        ];
        setMenuKeyList(isAccountMenu ? accountKeyList : gameKeyList);
    }, [modeStatus, isAccountMenu, accountKeyList]);

    useEffect(() => {
        if (prevMenu !== activeMenu && !collapsed) {
            setOpenKeys(openKeys);
        }
    }, [activeMenu, collapsed, prevMenu, openKeys]);

    window.onhashchange = () => {
        setSelectedKeys(selectedKeys);
        setOpenKeys(selectedKeys);
    };

    const onCollapse = (collapsed: boolean) => {
        if (collapsed) {
            setOpenKeys([]);
            setTimeout(() => {
                setCollapsed(collapsed);
            }, 0);
        } else {
            setOpenKeys(openKeys);
            setCollapsed(collapsed);
        }
    };

    const setSpecialRule = (
        clickKey: string,
        curSubMenu: any,
        param: {
            path: string, openKeys: Array<string>
        }
    ) => {
        if(clickKey === 'brandManage' && !!modeStatus?.isBrandProMode) {
            param.path = '/universialGame/brandPro';
            param.openKeys = curSubMenu?.subs?.length > 0
                ? [clickKey, 'brandPro']
                : [clickKey];
        }
    };

    useMount(() => {
        setAppId(appId
            ? appId : universialAppList.length > 0
                ? universialAppList[0].appId : '');
    });

    return (
        <Layout className={styles.layoutWrapper}>
            <Header activeMenu={activeMenu}/>
            <Layout className={classnames(
                styles.mainWrapper,
                {[styles.collapsed]: collapsed}
            )}>
                {subMenus && subMenus.length > 0 && (
                    <Sider
                        className={styles.sider}
                        collapsible={collapsible}
                        collapsed={collapsible && collapsed}
                        onCollapse={onCollapse}
                        collapsedWidth={48}
                        trigger={null}
                    >
                        {/* 非账号首页 展示 返回按钮 */}
                        {pathname.indexOf('universialGame') > -1
                            && !isAccountMenu
                            && (
                                <Button
                                    icon={<LeftOutlined />}
                                    className={classnames(styles.commonBtn, styles.backBtn)}
                                    onClick={() => history.push('/universialGame/home')}
                                >返回平台首页</Button>
                            )
                        }
                        {
                            pathname.indexOf('GuildCreate') < 0 && hasGuild && (
                                <Button
                                    icon={<PlusOutlined/>}
                                    type="primary"
                                    className={styles.commonBtn}
                                    onClick={() => history.push('/guildManage/GuildCreate')}
                                >创建新公会</Button>
                            )
                        }
                        {/* 账号首页 展示 创建游戏按钮 */}
                        {isAccountMenu && (
                            collapsed ? (
                                <Tooltip placement="right" title={'创建游戏'}>
                                    <Button
                                        type="primary"
                                        icon={<PlusOutlined/>}
                                        className={styles.commonBtn}
                                        style={{width: '38px'}}
                                        onClick={() => {
                                            history.push('/universialGame/creation');
                                        }}
                                    ></Button>
                                </Tooltip>
                            ) : (
                                <Button
                                    id='createGameBtn'
                                    type="primary"
                                    icon={<PlusOutlined/>}
                                    className={styles.commonBtn}
                                    style={{width: '150px'}}
                                    onClick={() => {
                                        if(userInfo?.newAccountFirst === 1 && isDriver === true) {
                                            return;
                                        }
                                        history.push('/universialGame/creation');
                                    }}
                                >创建游戏</Button>
                            )
                        )}

                        {/* 非账号首页 展示 切换游戏按钮 */}
                        {pathname.indexOf('universialGame') > -1
                            && !isAccountMenu
                            && (universialAppList.length > 0 || gameName)
                            && <GameSwitch
                                collapsed={collapsed}
                                getGameName={(name: string) => setGameName(name)}
                            />
                        }

                        <Menu
                            theme="light"
                            mode="inline"
                            selectedKeys={_selectedKeys}
                            openKeys={_openKeys}
                            onOpenChange={(key: any) => {
                                const clickKey = key[key.length - 1];
                                const curSubMenu: any = subMenus.find(item => item.key === clickKey);
                                const param = {
                                    path: curSubMenu?.subs?.length > 0 ? curSubMenu.subs[0].path : curSubMenu?.path,
                                    openKeys: curSubMenu?.subs?.length > 0
                                        ? [clickKey, curSubMenu.subs[0].key]
                                        : [clickKey]
                                };
                                setSpecialRule(clickKey, curSubMenu, param);
                                setOpenKeys(param.openKeys);
                                setSelectedKeys(param.openKeys);
                                history.push(param.path);
                            }}
                        >
                            {subMenus.map(menu => (
                                // 开通超品不展示
                                (['proIntroduce', 'postTBarIntro'].indexOf(menu.key) > -1 && hasBrandProGame) ? (
                                    <Fragment key={menu.key}></Fragment>
                                ) : (
                                    (menuKeyList.includes(menu.key) || hasAgent || hasGuild)
                                    && iterMenu(
                                        menu,
                                        hasCertification,
                                        hasAgent,
                                        userInfo,
                                        isDriver,
                                        modeStatus,
                                        hasGuild
                                    )
                                )
                            ))}
                        </Menu>
                    </Sider>
                )}
                <Content className={styles.contentWrapper}>
                    <div id="button7" style={{minWidth: 1020}}>
                        {children}
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
};

const useNavigation = (pathname: string) => {
    const activeMenu = React.useMemo(() => pathname.split('/')[1], [pathname]);
    const {routes, userInfo} = React.useContext(UserContext);

    const currentMenu = routes.menus.find((item:any) => item.key === activeMenu);
    if (!currentMenu) {
        return {
            activeMenu: '',
            subMenus: [],
            openKeys: [],
            selectedKeys: [],
            collapsible: true
        };
    }
    const subMenus = currentMenu && 'subs' in currentMenu ? currentMenu.subs : [];

    const constructPath = (menu: GPMenu, paths: GPMenu[]): GPMenu[] | undefined => {
        if ('redirect' in menu) {
            return undefined;
        }

        if(menu.key === 'brand' && userInfo?.userSource === 5) {
            return undefined;
        }
        if ('path' in menu) {
            if (pathname.toLocaleLowerCase().startsWith(menu.path.toLocaleLowerCase())) {
                return [...paths, menu];
            }

            return undefined;
        }
        return menu.subs
            .map(sub => constructPath(sub, [...paths, menu]))
            .find(item => item);
    };

    const activePath = constructPath(currentMenu as GPMenu, []) || [];
    const openKeys = activePath.map(item => item.key).slice(1);

    const selectedKeys = activePath.length === 0 ? []
        : (activePath[activePath.length - 1] as MenuWithComponent).activeMenu || openKeys;

    return {
        activeMenu,
        subMenus,
        openKeys,
        selectedKeys,
        collapsible: currentMenu.collapsible !== undefined
            ? currentMenu.collapsible
            : true
    };
};

export default SiderFLayout;