import React from 'react';
import {Spin, Result} from 'antd';
import {Route, Switch, Redirect, useHistory} from 'react-router-dom';
import Loadable, {LoadingComponentProps} from 'react-loadable';
import {GPMenu, MenuWithComponent} from './universialConfig';
import pageMap from 'pages/pageMap';
import UserContext from 'context/UserContext';
import {useCreation} from '@baidu/gp-hooks';

const PageLoading: React.FC<LoadingComponentProps> = ({isLoading, error}) => {
    // Handle the loading state
    if (isLoading) {
        return <Spin spinning>
            <div style={{height: 600}}/>
        </Spin>;
    }
    // Handle the error state
    if (error) {
        console.log(error);
        return <Result status="500" subTitle="页面出错了，请刷新重试"/>;
    }
    return null;
};

export const iterteMenu = (menu: GPMenu, hasLogin: boolean, hasCertification: boolean) => {
    if (menu.needLogin === true && !hasLogin) {
        return undefined;
    }
    if (menu.needCertification === true && !hasCertification) {
        return undefined;
    }

    const pages: MenuWithComponent[] = [];
    const findMenuWithComponent = (menu: GPMenu) => {
        if ('component' in menu) {
            pages.push(menu);
            return;
        }
        if ('subs' in menu) {
            menu.subs.forEach(sub => findMenuWithComponent(sub));
        }
    };

    findMenuWithComponent(menu);

    return pages.map(menu => {
        const path = menu.path + (
            menu.params
                ? `${menu.params.map(param => `/:${param}?`).join('')}`
                : ''
        );
        return <Route
            path={path}
            key={menu.path}
            exact={false}
            component={
                Loadable({
                    loading: PageLoading,
                    loader: pageMap[menu.component],
                    render(loaded, props) {
                        const Component = loaded.default;
                        return <Component {...props} />;
                    }
                })
            }
        >
        </Route>;
    });
};

const Routes: React.FC = () => {
    const history = useHistory();
    const {hasLogin, routes, statusInfo, userInfo} = React.useContext(UserContext);
    const hasCertification = !!(statusInfo?.hasCertification);

    const routeComps = useCreation(() => (
        [].concat(
            ...Object.keys(routes)
                .map(
                    (key: string) =>
                        [].concat(
                            ...(routes[key]
                                .map((i: any) => iterteMenu(i, hasLogin, hasCertification))
                            )
                        )
                )
        )
    ), []);

    if (window.location.hash !== '#/index' && userInfo?.companyStatus === 2) {
        history.replace('/index');
    }

    return (
        <Switch>
            <Route exact path="/" render={() => <Redirect to="/index" />} />
            {routeComps}
            <Route path="*" exact render={() => <Redirect to="/index" />} />
        </Switch>
    );
};

export default Routes;