import {HeaderMenu} from 'routes/universialConfig';

const menu: HeaderMenu = {
    key: 'guildManage',
    title: '公会管理',
    defaultPath: '/guildManage/guildInfo',
    needLogin: true,
    subs: [
        {
            key: 'guildManage',
            title: '公会管理',
            icon: 'ic_cooperation',
            subs: [
                {
                    key: 'guildInfo',
                    title: '公会信息',
                    path: '/guildManage/guildInfo',
                    component: 'GuildInfo'
                },
                {
                    key: 'guildMemberManage',
                    title: '成员管理',
                    path: '/guildManage/guildMemberManage',
                    component: 'GuildMemberManage'
                },
                {
                    key: 'guildCreate',
                    title: '新增公会',
                    nav: false,
                    path: '/guildManage/GuildCreate',
                    activeMenu: ['guildInfo'],
                    component: 'GuildCreate'
                }
            ]
        },
        {
            key: 'guildFinanceManage',
            title: '财务管理',
            icon: 'ic_walkthrough',
            auth: 'AUTH_HDD||AUTH_HCST',
            subs: [
                {
                    key: 'guildOrderManage',
                    title: '订单管理',
                    path: '/guildManage/guildOrderManage',
                    component: 'GuildOrderManage',
                    auth: 'AUTH_HDD'
                },
                {
                    key: 'guildRewardManage',
                    title: '打赏管理',
                    path: '/guildManage/guildRewardManage',
                    component: 'GuildRewardManage',
                    auth: 'AUTH_HCST'
                },
                {
                    key: 'guildBillManage',
                    title: '对账管理',
                    path: '/guildManage/guildBillManage',
                    component: 'GuildBillManage',
                    auth: 'AUTH_ZZSGL && (AUTH_HDD||AUTH_HCST)'
                }
            ]
        },
        {
            key: 'accountMemberManage',
            title: '权限管理',
            auth: 'AUTH_YZSHPGL',
            iconCustom: 'icon-account-member-manage',
            path: '/guildManage/accountMemberManage',
            component: 'AccountMemberManage' // 三个身份公用组件
        }
    ]
};

export default menu;