import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router';
import {useLocalStorageState, useRequest} from '@baidu/gp-hooks';
import universialGameApi from 'api/universialGame';
import UserContext from './UserContext';

const GameInfoContext = React.createContext(
    {
        appId: '',
        setAppId: () => {},
        universialAppList: [],
        getUniversialAppList: () => {},
        modeStatus: undefined,
        modeRefresh: async () => {}
    } as {
        appId: string | undefined
        setAppId: (id: string) => void
        universialAppList: any[]
        getUniversialAppList: (params?: {gameName: string}) => void
        modeStatus: any
        modeRefresh: () => Promise<any>
    }
);

export const GameInfoProvider: React.FC = ({children}) => {
    const [appId, setAppId] = useLocalStorageState('appId', '');
    const {hasLogin, userInfo} = React.useContext(UserContext);
    const {pathname} = useLocation();
    const [isReady, setIsReady] = useState<boolean>(false);

    useEffect(() => {
        setIsReady(hasLogin && !!userInfo
            && userInfo?.userSource !== undefined && ![5, 6].includes(userInfo?.userSource)
            && pathname.indexOf('/index') < 0);
    }, [hasLogin, userInfo, pathname]);
    // 已创建游戏列表
    const {
        data: universialAppList,
        request: getUniversialAppList
    } = useRequest(
        universialGameApi.getUniversalAppList,
        {
            onSuccess(res) {
                if (res && res.length > 0) {
                    setAppId(appId || res[0].appId);
                }
            },
            ready: isReady
        }
    );

    const {data: modeStatus, refresh: modeRefresh} = useRequest(
        () => universialGameApi.getExtralInfo({appId}),
        {
            ready: !!appId && isReady && pathname.indexOf('/universialGame/home') < 0,
            refreshDeps: [appId]
        }
    );

    return (
        <GameInfoContext.Provider value={{
            appId,
            setAppId,
            universialAppList,
            getUniversialAppList,
            modeStatus,
            modeRefresh
        }}>
            {children}
        </GameInfoContext.Provider>
    );
};

export default GameInfoContext;