import axios from 'plugins/axios';

const DocApi = {
    getList(): Promise<any> {
        return axios.post('/gpbusiness/doc/getList');
    },
    getDetail(params: {id: string}): Promise<any> {
        return axios.post('/gpbusiness/doc/getDetail', params);
    }
};

export default DocApi;
