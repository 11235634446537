import React, {Fragment, useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {Modal} from 'antd';
import {usePersistFn, useRequest} from '@baidu/gp-hooks';
import universialRoutes, {GPMenu, RouteConfig} from 'routes/universialConfig';
import userApi from 'api/user';
import messageApi from 'api/message';
import certificationApi from 'api/certification';
import dompurify from 'dompurify';
import styles from './style.module.less';
import guildApi from 'api/guild';
import {DEFAUT_PASS_PORTRAIT} from 'utils/consts';

interface AuthMap {
    [index: string]: boolean
};

export interface UserInfo {
    passPortrait: string, // pass头像
    username: string,
    company: string,
    isNewCp: boolean,
    indexFirst: number, // 新手引导——是否认证成功后第一次进入首页
    gameFirst: number, // 新手引导——是否认证成功后第一次进入游戏管理页
    financeFirst: number, // 新手引导——是否认证成功后第一次进入百度财务页
    newIndexFirst: number, // 新版-新手引导——是否认证成功后第一次进入首页
    newGameFirst: number, // 新版-新手引导——是否认证成功后第一次进入游戏首页
    newAccountFirst: number, //新版- 新手引导——是否认证成功后第一次进入账号首页
    isPrivilegeFirst: number, // 权益新手引导
    newFinanceFirst: number, //新版- 新手引导——是否认证成功后第一次进入百度财务
    cpIcon: string,
    cpName: string,
    isWhite: number, // 白名单用户
    auth: AuthMap,
    oauthId: string,
    userSource?: number,
    companyStatus?: number, // 公司状态，1:可用，2:封禁
    hasGuild?: boolean, // 是否已创建公会
    accountSettledStatus?: number // 1 曾经注册过初始账号且已移除  2 其他
    showPatchAttentionlModal?: number // 是否展示补充材料提示
    isExempt?: number // 是否可以豁免补充材料弹窗
};

export interface StatusInfo {
    brandStatus: number // 品牌状态
    brandRejectReason: string // 品牌被驳回原因
    creditStatus: number // 账号认证状态
    creditRejectReason: string // 账号被驳回原因
    isFirst: number // 是否是第一次提交认证
    path: string, // 不同认证状态的跳转路径
    isAgree: number, // 是否已同意品牌授权
    hasCertification: boolean, // 是否已认证
    processOldCp: () => boolean // 判断是否是老CP，true——处理老CP逻辑，false——return
}

const UserContext = React.createContext(
    {
        inited: false,
        hasLogin: false,
        userInfo: undefined,
        routes: universialRoutes
    } as {
    inited: boolean,
    hasLogin: boolean,
    userInfo: UserInfo | undefined,
    userRefresh: () => Promise<any>,
    statusInfo: StatusInfo | undefined,
    statusRefresh: () => Promise<any>,
    routes: RouteConfig,
    isDriver: boolean,
    setIsDriver: (isDriver: boolean) => void
});

const filterMenuNoLogin = (menus: GPMenu[]) => {
    menus.forEach(menu => {
        if (!('subs' in menu)) {
            return;
        }
        menu.subs = menu.subs.filter(menu => {
            return !menu.auth;
        }) || [];
        filterMenuNoLogin(menu.subs);
    });
};


const getRouteByAuth = (userInfo: UserInfo, loading: boolean): RouteConfig => {
    const routeConfig: RouteConfig = {...universialRoutes};
    if (!userInfo) {
        if(loading) {
            return routeConfig;
        }
        routeConfig.menus = routeConfig.menus.filter(menu => {
            return !menu.auth;
        });

        filterMenuNoLogin(routeConfig.menus);
        return routeConfig;
    }
    const auth = userInfo.auth;
    // eslint-disable-next-line no-new-func
    const transform = (rule: string) => new Function(`"use strict"; return (${
        rule.replace(/[A-Z_]+/g, match => `${!!(match in auth && auth[match])}`)
    })`)();

    const filterMenu = (menus: GPMenu[]) => {
        menus.forEach(menu => {
            if (!('subs' in menu)) {
                return;
            }
            menu.subs = menu.subs.filter(menu => {
                const routerAuth = menu.auth;
                return (routerAuth ? transform(routerAuth) : true) && !(menu.newCp === false && userInfo?.isNewCp);
            });
            filterMenu(menu.subs);
        });
    };

    routeConfig.menus = routeConfig.menus.filter(menu => {
        const routerAuth = menu.auth;
        return (routerAuth ? transform(routerAuth) : true) && !(menu.newCp === false && userInfo?.isNewCp);
    });

    filterMenu(routeConfig.menus);
    // 出现补充资质弹窗的，将开发者的路由过滤掉
    routeConfig.menus = routeConfig.menus.filter(menu => {
        return !(menu.key === 'universialGame' && !!userInfo?.showPatchAttentionlModal && !userInfo?.isExempt);
    });
    return routeConfig;
};

export const UserProvider: React.FC = ({children}) => {
    const [isDriver, setIsDriver] = React.useState<boolean>(false);
    const history = useHistory();
    const {pathname} = useLocation();
    const [inited, setInited] = React.useState<boolean>(false);
    const [hasLogin, setHasLogin] = React.useState<boolean>(false);
    const [routes, setRoutes] = React.useState<RouteConfig>({
        menus: [],
        other: []
    });

    // 同意开发者服务协议
    const {request: setAgree} = useRequest(certificationApi.setAgree, {
        manual: true,
        onSuccess() {
            statusRefresh();
        }
    });

    const {
        loading,
        data: userInfo,
        request: userRefresh
    } = useRequest(userApi.getUserInfo, {
        formatResult(res) {
            if (res === 'UNAUTHORIZED' || !res) {
                setHasLogin(false);
                if (pathname.indexOf('/doc/') < 0) {
                    history.replace('/index');
                }
                return;
            }
            setHasLogin(true);
            localStorage.removeItem('version');
            const {auth = []} = JSON.parse(JSON.stringify(res));
            const authRes = auth.reduce((map: {[index: string]: boolean}, auth: string) => {
                map[auth] = true;
                return map;
            }, {});

            return {
                ...res,
                auth: authRes,
                passPortrait: res.passPortrait || DEFAUT_PASS_PORTRAIT,
                username: res.name,
                company: res.company,
                isNewCp: !!(res.isNewCp)
            };
        },
        onSuccess(res) {
            if(!res) {
                setInited(true);
                return;
            }
            if(res.companyStatus === 2) {
                Modal.warning({
                    title: '账号已冻结',
                    content: '该帐号涉嫌业务违规操作被暂时冻结。如有疑问，请联系game-bd@baidu.com',
                    okText: '确认'
                });
            }
            statusRequest();
            hasGuildRequest();

            !res && setInited(true);
        }
    });

    useEffect(() => {
        setRoutes(getRouteByAuth(userInfo, loading));
    }, [userInfo, loading]);

    const formatResult = usePersistFn((res: any) => {
        const {creditStatus, isFirst, isAgree} = res;

        return {
            hasCertification: creditStatus === 1,
            path: isFirst ? '/certification' : '/profile/certification',
            processOldCp: () => {
                if (creditStatus === 1) {
                    !isAgree && (
                        Modal.confirm({
                            title: '百度游戏开放平台开发者服务协议',
                            content: (
                                <Fragment>
                                    <div>在您进入并使用平台的过程中，您需要完成授权流程并通过点击同意的形式在线签署以下协议，请您务必仔细阅读，充分理解协议中的条款内容后再点击同意</div>
                                    <a
                                        href="#/doc/31"
                                        target="_Blank"
                                        rel="noreferrer"
                                    >《开发者服务协议》</a>
                                    <a
                                        href="#/doc/35"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >《游戏运营规范》</a>
                                </Fragment>
                            ),
                            cancelText: '拒绝',
                            okText: '同意',
                            onOk: setAgree
                        })
                    );
                    return true;
                }
                return false;
            }
        };
    });

    // 认证信息
    const {
        data: statusInfo,
        refresh: statusRefresh,
        request: statusRequest
    } = useRequest(certificationApi.getStatus, {
        initialData: null,
        ready: hasLogin && userInfo,
        manual: true,
        onSuccess(res) {
            setInited(true);
            // 帮助中心/老CP已同意授权，不再处理老CP
            pathname.indexOf('/doc/') < 0 && !res.isAgree && res.processOldCp();
        },
        formatResult: (res: any) => {
            const {hasCertification, path, processOldCp} = formatResult(res);
            return {
                ...res,
                hasCertification,
                path,
                processOldCp
            };
        }
    });
    const clickContent = (e: any) => {
        const divDom = document.createElement('div');
        const newImgNode = e.target.cloneNode();
        newImgNode.style = 'width: auto; max-width: 100%; display:block; margin:0 auto';
        divDom.appendChild(newImgNode);
        const imgStr = divDom.innerHTML;
        if (e.target.tagName === 'IMG') {
            Modal.info({
                maskClosable: true,
                className: 'img-preview-wrap',
                width: '70%',
                icon: null,
                okType: 'link',
                okText: '关闭',
                // bca-disable-next-line
                content: <div dangerouslySetInnerHTML = {{__html: dompurify.sanitize(`${imgStr}`)}}></div>
            });
        }
        if (e.target.tagName === 'A') {
            e.preventDefault();
            window.open(e.target.href);
        }
    };

    // 公告弹框
    useRequest(messageApi.getSysWindow, {
        ready: !!(statusInfo?.hasCertification),
        initialData: [{
            isRead: 0,
            title: '',
            content: ''
        }],
        onSuccess: (res = []) => {
            res.forEach((item: any) => {
                const {popWindow, title, content, firstPop} = item;
                const reg = new RegExp('<img', 'gi');
                const txt = content.replace(reg, '<img style="width: 200px;"');
                // bca-disable-next-line
                const richTextContent = <div dangerouslySetInnerHTML = {{__html: dompurify.sanitize(`${txt}`)}}
                    onClick={clickContent}/>;

                if (popWindow && title) {
                    const modal = Modal.warning({
                        width: '50%',
                        title,
                        content: richTextContent,
                        okButtonProps: {
                            disabled: firstPop
                        },
                        okType: 'default',
                        okText: firstPop ? '5s后可关闭' : '关闭',
                        className: styles.popWindow
                    });
                    if (!firstPop) {
                        return;
                    }
                    let seconds = 5;
                    let timer: any = null;
                    timer = setInterval(() => {
                        seconds -= 1;
                        if (seconds === 0) {
                            modal.update({
                                okButtonProps: {
                                    disabled: false
                                },
                                okText: '关闭'
                            });
                            clearInterval(timer);
                            return;
                        }
                        modal.update({
                            okText: `${seconds}s后可关闭`
                        });
                    }, 1000);
                }
            });
        }
    });

    // 公会信息
    const {
        data: hasGuild,
        request: hasGuildRequest
    } = useRequest(
        () => guildApi.getGuildList({page: 1, size: 1}),
        {
            initialData: null,
            ready: hasLogin && userInfo && userInfo.userSource === 6, // 公会身份可调用公会列表接口查询是否已创建公会
            manual: true,
            onSuccess(res) {
                setInited(true);
            },
            formatResult: (res: any) => {
                return res?.listData && res?.listData.length > 0;
            }
        }
    );

    return (
        <UserContext.Provider value={{
            inited,
            hasLogin,
            userInfo: {
                ...userInfo,
                hasGuild: hasGuild || false
            },
            userRefresh,
            statusInfo,
            statusRefresh,
            routes,
            isDriver,
            setIsDriver
        }}>
            {children}
        </UserContext.Provider>
    );
};

export default UserContext;