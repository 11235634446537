import {HeaderMenu} from 'routes/universialConfig';

const menu: HeaderMenu = {
    key: 'universialGame',
    title: '新版创建游戏',
    defaultPath: '/universialGame/gameHome',
    needLogin: true,
    needCertification: true,
    subs: [
        {
            key: 'home',
            title: '首页',
            component: 'Home',
            auth: 'AUTH_YXKFZZY',
            path: '/universialGame/home',
            icon: 'ic_game'
        },
        {
            key: 'gameHome',
            title: '游戏首页',
            component: 'GameHome',
            path: '/universialGame/gameHome',
            icon: 'ic_gamehomepage'
        },
        {
            key: 'gameCreation',
            path: '/universialGame/creation',
            auth: 'AUTH_CJYX',
            title: '游戏创建',
            component: 'UniversialGameCreation'
        },
        {
            key: 'universalGameManage',
            title: '基础管理',
            auth: 'AUTH_YXJCXX',
            icon: 'ic_gameinfo',
            subs: [
                {
                    key: 'UniversalGameDetail',
                    title: '基础信息',
                    component: 'UniversialGameDetail',
                    path: '/universialGame/detail',
                    params: ['appId']
                }
            ]
        },
        {
            key: 'brandManage',
            title: '品牌管理',
            icon: 'ic_brand',
            auth: 'AUTH_PPYXXX || AUTH_YXBK || AUTH_YXDT',
            subs: [
                {
                    key: 'brand',
                    title: '游戏信息',
                    path: '/universialGame/brandManage',
                    component: 'UniversialBrandDetail',
                    auth: 'AUTH_PPYXXX'
                },
                {
                    key: 'gameWiki',
                    title: '游戏百科',
                    path: '/universialGame/gameWiki',
                    component: 'UniversialGameWiki',
                    auth: 'AUTH_YXBK'
                },
                {
                    key: 'creation',
                    title: '编辑动态',
                    path: '/universialGame/dynamicCreation',
                    component: 'DynamicCreation',
                    params: ['appId', 'dynamicId'],
                    nav: false,
                    activeMenu: ['brandManage', 'list'],
                    auth: 'AUTH_YXDT'
                },
                {
                    key: 'list',
                    title: '游戏动态',
                    path: '/universialGame/dynamicList',
                    component: 'DynamicList',
                    auth: 'AUTH_YXDT'
                },
                {
                    key: 'brandPro',
                    title: '游咖星选',
                    auth: 'AUTH_YKXX',
                    path: '/universialGame/brandPro',
                    component: 'UniversialBrandPro'
                },
                {
                    key: 'proDetail',
                    title: '游咖星选明细',
                    auth: 'AUTH_YKXX',
                    path: '/universialGame/brandProDetail',
                    component: 'UniversialBrandProDetail',
                    activeMenu: ['brandManage', 'brandPro'],
                    nav: false
                },
                {
                    key: 'postBar',
                    title: '贴吧合作',
                    path: '/universialGame/postBar',
                    component: 'UniversialPostBar'
                }
            ]
        },
        {
            key: 'transport',
            title: '联运管理',
            icon: 'ic_cooperation',
            auth: 'AUTH_LYGL',
            subs: [
                {
                    key: 'transportManage',
                    title: '游戏信息',
                    path: '/universialGame/transportManage',
                    component: 'UniversialTransportManage'
                },
                {
                    key: 'uploadByApi',
                    title: 'api方式上传',
                    path: '/universialGame/uploadByApi',
                    component: 'UniversialUploadByApi',
                    auth: 'AUTH_APIJSJSXC'
                },
                {
                    key: 'gift',
                    title: '联运礼包',
                    path: '/universialGame/transportGift',
                    component: 'Gift'
                },
                {
                    key: 'giftDetail',
                    title: '联运礼包详情',
                    path: '/universialGame/transportGiftDetail',
                    params: ['appId', 'id'],
                    component: 'GiftDetail',
                    nav: false
                },
                {
                    key: 'giftCreation',
                    title: '联运礼包新建',
                    path: '/universialGame/transportGiftCreation',
                    component: 'GiftCreation',
                    params: ['businessType'],
                    nav: false
                }
            ]
        },
        {
            key: 'universalFinance',
            title: '财务管理',
            icon: 'ic_financial01',
            auth: 'AUTH_BDCW || AUTH_DKCW',
            subs: [
                {
                    key: 'baiduCashier',
                    title: '百度财务（新）',
                    auth: 'AUTH_DRBPWXL',
                    path: '/universialGame/baiduCashierFinance',
                    component: 'UniversialBaiduCashierFinance'
                },
                {
                    key: 'baiduCashierDetail',
                    title: '百度财务详情',
                    auth: 'AUTH_DRBPWXL',
                    nav: false,
                    path: '/universialGame/baiduCashierDetail',
                    params: ['id'],
                    activeMenu: ['universalFinance', 'baiduCashier'],
                    component: 'UniversialBaiduCashierFinanceDetail'
                },
                {
                    key: 'baidu',
                    title: '百度财务',
                    auth: 'AUTH_BDCW',
                    path: '/universialGame/baiduFinance',
                    component: 'UniversialBaiduFinance'
                },
                {
                    key: 'baiduDetail',
                    title: '百度财务详情',
                    auth: 'AUTH_BDCW',
                    nav: false,
                    path: '/universialGame/baiduDetail',
                    params: ['id'],
                    activeMenu: ['universalFinance', 'baidu'],
                    component: 'UniversialBaiduFinanceDetail'
                },
                {
                    key: 'invoice',
                    title: '上传发票',
                    auth: 'AUTH_BDCW',
                    path: '/universialGame/invoice',
                    params: ['id'],
                    nav: false,
                    activeMenu: ['baidu'],
                    component: 'UniversialUploadInvoice'
                },
                {
                    key: 'duoku',
                    title: '多酷财务',
                    auth: 'AUTH_DKCW',
                    path: '/universialGame/duokuFinance',
                    component: 'UniversialDuokuFinance',
                    newCp: false
                },
                {
                    key: 'duokuDetail',
                    title: '多酷财务详情',
                    auth: 'AUTH_DKCW',
                    nav: false,
                    path: '/universialGame/duokuDetail',
                    params: ['id'],
                    activeMenu: ['duoku'],
                    component: 'UniversialDuokuFinanceDetail',
                    newCp: false
                }
            ]
        },
        {
            key: 'contract',
            title: '合同管理',
            auth: 'AUTH_BDHT || AUTH_DKHT',
            icon: 'ic_contractmanagement',
            subs: [
                {
                    key: 'baiduContractList',
                    title: '百度合同',
                    auth: 'AUTH_BDHT',
                    path: '/universialGame/baidu/contractList',
                    component: 'UniversialBaiduContractList'
                },
                {
                    key: 'baiduContractDetail',
                    title: '合同详情',
                    auth: 'AUTH_BDHT',
                    path: '/universialGame/baidu/contractDetail',
                    component: 'UniversialBaiduContractDetail',
                    params: ['opt', 'appId', 'id'],
                    nav: false,
                    activeMenu: ['contract', 'baiduContractList']
                },
                {
                    key: 'duokuContractList',
                    title: '多酷合同',
                    auth: 'AUTH_DKHT',
                    path: '/universialGame/duoku/contractList',
                    component: 'UniversialDuokuContractList',
                    newCp: false
                },
                {
                    key: 'duokuContractDetail',
                    title: '合同详情',
                    auth: 'AUTH_DKHT',
                    path: '/universialGame/duoku/contractDetail',
                    component: 'UniversialDuokuContractDetail',
                    params: ['opt', 'appId', 'id'],
                    nav: false,
                    activeMenu: ['contract', 'duokuContractList'],
                    newCp: false
                }
            ]
        },
        {
            key: 'gameContract',
            title: '合同管理',
            auth: 'AUTH_BDHT || AUTH_DKHT',
            icon: 'ic_contractmanagement',
            subs: [
                {
                    key: 'baiduGameContractList',
                    title: '百度合同',
                    auth: 'AUTH_BDHT',
                    path: '/universialGame/baidu/gameContractList',
                    component: 'UniversialBaiduGameContractList'
                },
                {
                    key: 'baiduGameContractDetail',
                    title: '合同详情',
                    auth: 'AUTH_BDHT',
                    path: '/universialGame/baidu/gameContractDetail',
                    component: 'UniversialBaiduGameContractDetail',
                    params: ['opt', 'appId', 'id'],
                    nav: false,
                    activeMenu: ['gameContract', 'baiduGameContractList']
                },
                {
                    key: 'duokuGameContractList',
                    title: '多酷合同',
                    auth: 'AUTH_DKHT',
                    path: '/universialGame/duoku/gameContractList',
                    component: 'UniversialDuokuGameContractList',
                    newCp: false
                },
                {
                    key: 'duokuGameContractDetail',
                    title: '合同详情',
                    auth: 'AUTH_DKHT',
                    path: '/universialGame/duoku/gameContractDetail',
                    component: 'UniversialDuokuGameContractDetail',
                    params: ['opt', 'appId', 'id'],
                    nav: false,
                    activeMenu: ['gameContract', 'duokuGameContractList'],
                    newCp: false
                }
            ]
        },
        {
            key: 'accountMemberManage',
            title: '权限管理',
            auth: 'AUTH_YZSHPGL',
            iconCustom: 'icon-account-member-manage',
            path: '/universialGame/accountMemberManage',
            component: 'AccountMemberManage' // 公用组件
        },
        {
            key: 'proIntroduce',
            title: '游咖星选',
            component: 'UniversialBrandProIntro',
            path: '/universialGame/proIntroduce',
            icon: 'ic_brand'
        },
        {
            key: 'postTBarIntro',
            title: '贴吧合作',
            path: '/universialGame/postTBarIntro',
            iconCustom: 'icon-postbar',
            component: 'UniversialPostBar' // 与品牌管理中的贴吧组件共用一个
        }
    ]
};

export default menu;