import {HeaderMenu} from 'routes/universialConfig';

const menu: HeaderMenu = {
    key: 'profile',
    title: '个人中心',
    defaultPath: '/profile/finance',
    nav: false,
    needLogin: true,
    subs: [
        {
            key: 'certification',
            title: '认证信息',
            path: '/profile/certification',
            auth: 'AUTH_RZXXGL',
            component: 'CertificationProfile'
        },
        {
            key: 'brand',
            title: '企业品牌信息',
            path: '/profile/brand',
            auth: 'AUTH_PPXX',
            needCertification: true,
            component: 'Brand'
        },
        {
            key: 'finance',
            title: '财务信息',
            path: '/profile/finance',
            auth: 'AUTH_CWZZGL',
            needCertification: true,
            component: 'Finance'
        },
        {
            key: 'team',
            title: '团队账号',
            path: '/profile/team',
            auth: 'AUTH_TDZHGL',
            component: 'Team'
        }
    ]
};

export default menu;