import React from 'react';
import {withRouter, useLocation, useHistory} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {Modal, Spin} from 'antd';
import {pathToRegexp} from 'path-to-regexp';
import Routes from 'routes/index';
import UserContext from 'context/UserContext';
import SiderLayout from '../SiderLayout';
import PublicLayout from '../PublicLayout';
import DocLayout from '../DocLayout';
import IndexLayout from '../IndexLayout';
import config from '../../config/index';

type LayoutMapType = {
    [index: string]: React.ComponentType
};

const layoutMap: LayoutMapType = {
    primary: SiderLayout,
    doc: DocLayout,
    index: IndexLayout,
    public: PublicLayout
};

const queryLayout = (layouts: Array<any>, pathname: string) => {
    let result = 'public';
    const isMatch: (reg: string|RegExp) => boolean
        = reg => reg instanceof RegExp
            ? reg.test(pathname)
            : !!pathToRegexp(reg).exec(pathname);

    for(const item of layouts) {
        let include = false;
        let exclude = false;
        if (item.include) {
            include = item.include.some(isMatch);
        }
        if (include && item.exclude) {
            exclude = item.exclude.some(isMatch);
        }

        if (include && !exclude) {
            result = item.name;
            break;
        }
    }
    return result;
};

export const showSupplyInfoModal = (onOk?: () => void) => {
    Modal.info({
        title: '收银台升级补充材料',
        content: ( // eslint-disable-next-line
            <span style={{color: 'black'}}>您好，为了保证财务结算顺利进行，平台收银台即将进行升级。您的相关认证信息需要及时补充，通过后可继续使用平台功能。如特殊情况可联系您对接的商务。</span>
        ),
        okText: '立即填写',
        onOk
    });
};

const BaseLayout: React.FC = () => {
    const history = useHistory();
    const {inited, userInfo} = React.useContext(UserContext);
    const {pathname} = useLocation();
    const layoutType = queryLayout(config.layouts, pathname);
    const Container = layoutMap[layoutType];

    React.useEffect(() => {
        if (!!userInfo?.showPatchAttentionlModal && !userInfo?.isExempt && !pathname.startsWith('/doc/')) {
            const onOk = () => history.push('/supplyInfoSubmit');
            showSupplyInfoModal(onOk);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo?.showPatchAttentionlModal, userInfo?.isExempt]);

    return (
        <React.Fragment>
            <Helmet>
                <title>{config.siteName}</title>
            </Helmet>
            {!inited
                ? (
                    <Spin>
                        <div style={{height: '100vh'}}></div>
                    </Spin>
                ) : (
                    <Container>
                        <Routes />
                    </Container>
                )
            }
        </React.Fragment>
    );
};

export default withRouter(BaseLayout);