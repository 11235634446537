import React from 'react';
import {Layout} from 'antd';
import Header from '../components/Header';
import styles from './style.module.less';

const {Content} = Layout;

const IndexLayout: React.FC = ({children}) => {
    return (
        <Layout className={styles.layoutWrapper}>
            <Header/>
            <Content className={styles.contentWrapper}>
                {children}
            </Content>
        </Layout>
    );
};

export default IndexLayout;