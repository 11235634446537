/* eslint-disable max-len */
interface PageMap {
    [index: string]: () => Promise<{default: React.ComponentType<any>}>
}

const pages: PageMap = {
    'Index': () => import(/* webpackChunkName: "Index" */'pages/Index'),
    'Finance': () => import(/* webpackChunkName: "Finance" */'pages/Profile/subpages/Finance'),
    'MessageCenter': () => import(/* webpackChunkName: "MessageCenter" */'pages/MessageCenter'),
    'CertificationProfile': () => import(/* webpackChunkName: "CertificationProfile" */'pages/Profile/subpages/Certification'),
    'Brand': () => import(/* webpackChunkName: "Brand" */'pages/Profile/subpages/Brand'),
    'BrandAuthorization': () => import(/* webpackChunkName: "BrandAuthorization" */'pages/Brand'),
    'Home': () => import(/* webpackChunkName: "Home" */'pages/UniversialGame/subpages/Home'),
    'GameHome': () => import(/* webpackChunkName: "GameHome" */'pages/UniversialGame/subpages/GameHome'),
    'UniversialGameCreation': () => import(/* webpackChunkName: "UniversialGameCreation" */'pages/UniversialGame/subpages/GameCreation'),
    'UniversialGameDetail': () => import(/* webpackChunkName: "UniversialGameDetail" */'pages/UniversialGame/subpages/GameDetail'),
    'UniversialCertification': () => import(/* webpackChunkName: "UniversialCertification" */'pages/CertificationUniversial'),
    'UniversialCooperationMode': () => import(/* webpackChunkName: "UniversialCooperationMode" */'pages/UniversialGame/subpages/CooperationMode'),
    'UniversialCooperationTransport': () => import(/* webpackChunkName: "UniversialCooperationTransport" */'pages/UniversialGame/subpages/CooperationTransport'),
    'UniversialCooperationBrand': () => import(/* webpackChunkName: "UniversialCooperationBrand" */'pages/UniversialGame/subpages/CooperationBrand'),
    'UniversialTransportManage': () => import(/* webpackChunkName: "UniversialTransportManage" */'pages/UniversialGame/subpages/TransportCooperation/TransportManage'),
    'UniversialUploadByApi':  () => import(/* webpackChunkName: "UniversialUploadByApi" */'pages/UniversialGame/subpages/UniversialUploadByApi'),
    'UniversialBrandDetail': () => import(/* webpackChunkName: "UniversialBrandDetail" */'pages/UniversialGame/subpages/CooperationBrandDetail'),
    'UniversialGameWiki': () => import(/* webpackChunkName: "UniversialGameWiki" */'pages/UniversialGame/subpages/GameWiki'),
    'DynamicCreation': () => import(/* webpackChunkName: "DynamicCreation" */'pages/UniversialGame/subpages/DynamicCreation'),
    'DynamicList': () => import(/* webpackChunkName: "DynamicList" */'pages/UniversialGame/subpages/DynamicList'),
    'AgentOperator': () => import(/* webpackChunkName: "AgentOperator" */'pages/AgentOperator'),
    'AgentRouteHome': () => import(/* webpackChunkName: "AgentRouteHome" */'pages/AgentRoute/subpages/Home'),
    'AgentRouteBussinessContract': () => import(/* webpackChunkName: "AgentRouteBussinessContract" */'pages/AgentRoute/subpages/BussinessContract'),
    'AgentRouteContractList': () => import(/* webpackChunkName: "AgentRouteContractList" */'pages/AgentRoute/subpages/ContractList'),
    'CertificationPartner': () => import(/* webpackChunkName: "CertificationPartner" */'pages/AgentOperator/CertificationPartner'),
    'CertificationGuild': () => import(/* webpackChunkName: "CertificationPartner" */'pages/AgentOperator/CertificationGuild'),
    'UniversialDuokuFinance': () => import(/* webpackChunkName: "UniversialDuokuFinance" */'pages/Finance/subpages/DuokuFinance'),
    'UniversialDuokuFinanceDetail': () => import(/* webpackChunkName: "UniversialDuokuFinanceDetail" */'pages/Finance/subpages/DuokuFinanceDetail'),
    'UniversialBaiduFinance': () => import(/* webpackChunkName: "UniversialBaiduFinance" */'pages/Finance/subpages/BaiduFinance'),
    'UniversialBaiduFinanceDetail': () => import(/* webpackChunkName: "UniversialBaiduFinanceDetail" */'pages/Finance/subpages/BaiduFinanceDetail'),
    'UniversialBaiduCashierFinance': () => import(/* webpackChunkName: "UniversialBaiduCashierFinance" */'pages/Finance/subpages/BaiduCashierFinance'),
    'UniversialBaiduCashierFinanceDetail': () => import(/* webpackChunkName: "UniversialBaiduCashierFinanceDetail" */'pages/Finance/subpages/BaiduCashierFinanceDetail'),
    'UniversialUploadInvoice': () => import(/* webpackChunkName: "UniversialUploadInvoice" */'pages/Finance/subpages/UploadInvoice'),
    'UniversialBaiduContractList': () => import(/* webpackChunkName: "UniversialBaiduContractList" */'pages/Contract/subpages/BaiduContract'),
    'UniversialBaiduContractDetail': () => import(/* webpackChunkName: "UniversialBaiduContractDetail" */'pages/Contract/subpages/BaiduContractDetail'),
    'UniversialDuokuContractList': () => import(/* webpackChunkName: "UniversialDuokuContractList" */'pages/Contract/subpages/DuokuContract'),
    'UniversialDuokuContractDetail': () => import(/* webpackChunkName: "UniversialDuokuContractDetail" */'pages/Contract/subpages/DuokuContractDetail'),
    'Team': () => import(/* webpackChunkName: "Team" */'pages/Profile/subpages/Team'),
    'UniversialBaiduGameContractList': () => import(/* webpackChunkName: "UniversialBaiduGameContractList" */'pages/Contract/subpages/BaiduGameContract'),
    'UniversialBaiduGameContractDetail': () => import(/* webpackChunkName: "UniversialBaiduGameContractDetail" */'pages/Contract/subpages/BaiduGameContractDetail'),
    'UniversialDuokuGameContractList': () => import(/* webpackChunkName: "UniversialDuokuGameContractList" */'pages/Contract/subpages/DuokuGameContract'),
    'UniversialDuokuGameContractDetail': () => import(/* webpackChunkName: "UniversialDuokuGameContractDetail" */'pages/Contract/subpages/DuokuGameContractDetail'),
    'UniversialBrandPro': () => import(/* webpackChunkName: "UniversialBrandPro" */'pages/UniversialGame/subpages/BrandPro'),
    'UniversialBrandProDetail': () => import(/* webpackChunkName: "UniversialBrandProDetail" */'pages/UniversialGame/subpages/BrandProDetail'),
    'UniversialBrandProIntro': () => import(/* webpackChunkName: "UniversialBrandProIntro" */'pages/UniversialGame/subpages/BrandProIntro'),
    'UniversialPostBar': () => import(/* webpackChunkName: "UniversialPostBar" */'pages/UniversialGame/subpages/PostBar'),
    'ADemo': () => import(/* webpackChunkName: "ADemo" */'pages/Doc'),
    'GuildInfo': () => import(/* webpackChunkName: "GuildInfo" */'pages/Guild/subpages/GuildInfo'),
    'GuildMemberManage': () => import(/* webpackChunkName: "MemberManage" */'pages/Guild/subpages/GuildMemberManage'),
    'GuildCreate': () => import(/* webpackChunkName: "GuildCreate" */'pages/Guild/subpages/GuildCreate'),
    'GuildOrderManage': () => import(/* webpackChunkName: "GuildCreate" */'pages/Guild/subpages/GuildOrderManage'),
    'GuildRewardManage': () => import(/* webpackChunkName: "GuildReward" */'pages/Guild/subpages/GuildRewardManage'),
    'GuildBillManage': () => import(/* webpackChunkName: "GuildBill" */'pages/Guild/subpages/GuildBillManage'),
    'AccountMemberManage': () => import(/* webpackChunkName: "AccountMemberManage" */'pages/AccountMemberManage'),
    'SupplyInfoSubmit': () => import(/* webpackChunkName: "SupplyInfoSubmit" */'pages/SupplyInfoSubmit'),
    // 品牌/联运礼包
    'Gift': () => import(/* webpackChunkName: "Gift" */'pages/UniversialGame/subpages/Gift'),
    'GiftCreation': () => import(/* webpackChunkName: "GiftCreation" */'pages/UniversialGame/subpages/GiftCreation'),
    'GiftDetail': () => import(/* webpackChunkName: "GiftDetail" */'pages/UniversialGame/subpages/GiftDetail')

};

export default pages;