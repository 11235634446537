import axios from 'plugins/axios';

const certificationApi = {
    getDetail(params: GetDetailParams): Promise<any> {
        return axios.post('/gpbusiness/ocr/getDetail', params);
    },
    setData(params: any): Promise<any> {
        return axios.post('/gpbusiness/ocr/setData', params);
    },
    setCerInfo(params: any): Promise<any> {
        return axios.post('/gpbusiness/ocr/setCerInfo', params);
    },
    getAuthorizedDetail(): Promise<any> {
        return axios.post('/gpbusiness/ocr/getAuthorizedDetail');
    },
    getStatus(): Promise<any> {
        return axios.post('/gpbusiness/ocr/getStatus');
    },
    getBrandDetail(params: {appId?: string}): Promise<any> {
        return axios.post('/gpbusiness/brand/getDetail', params);
    },
    setBrandData(params: any): Promise<any> {
        return axios.post('/gpbusiness/brand/setData', params);
    },
    setSupplyCerInfo(params: any): Promise<any> {
        return axios.post('/gpbusiness/ocr/setSupplyCerInfo', params);
    },
    idCardOcr(params: {frontIdCardUrl: string, backIdCardUrl: string}): Promise<any> {
        return axios.post('/gpbusiness/ocr/idCardOcr', params);
    },
    setAgree(): Promise<any> {
        return axios.post('/gpbusiness/brand/setAgree');
    }
};

interface GetDetailParams {
    creditUrl: string
};

export default certificationApi;