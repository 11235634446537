import {Empty, Spin} from 'antd';
import React from 'react';
import dynameicEmpty from 'assets/imgs/empty.png';

interface Props {
    className?: string
    message?: string
    loading?: boolean
};

const EmptyComponent: React.FC<Props> = ({className, message = '暂无数据', loading}) => {
    return (
        <>
            {loading && <Spin className={className} style={{zIndex: 99, backgroundColor: '#fff'}} /> }
            {!loading && <Empty
                className={className}
                image={dynameicEmpty}
                imageStyle={{height: 56}}
                description={
                    <div style={{paddingTop: 10, fontSize: 14, color: 'rgba(0, 0, 0, 0.65)'}}>
                        {message}
                    </div>
                }
            />}
        </>
    );
};

export default EmptyComponent;