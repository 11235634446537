import axios from 'axios';
import axiosRetry from 'axios-retry';

import {
    AXIOS_DEFAULT_CONFIG,
    requestSuccessFunc,
    requestFailFunc,
    responseSuccessFunc,
    responseFailFunc
} from './config';

const axiosInstance = axios.create(AXIOS_DEFAULT_CONFIG);
axiosRetry(axiosInstance, {retries: 0, shouldResetTimeout: true}); // 全局默认不重试，如需重试，在需要重试的接口中自定义重试次数及参数

axiosInstance.interceptors.request.use(requestSuccessFunc, requestFailFunc);
axiosInstance.interceptors.response.use(responseSuccessFunc, responseFailFunc);

export default axiosInstance;