/**
 * 公会相关接口
 * 接口文档地址 http://apim.baidu.com/projects/453/editapi?id=14453&edit=0&activeTab=request
 */
import axios from 'plugins/axios';

const guildApi = {
    getGuildList(params: GetGuildListParams): Promise<any> {
        return axios.post('/gpbusiness/guild/getGuildList', params);
    },

    getGuildDetail(params: GetGuildDetailParams): Promise<any> {
        return axios.post('/gpbusiness/guild/guildDetail', params);
    },

    editGuild(params: EditGuildParams): Promise<any> {
        return axios.post('/gpbusiness/guild/edit', params);
    },

    deleteGuild(params: DeleteGuildParams): Promise<any> {
        return axios.post('/gpbusiness/guild/delete', params);
    },

    offlineGuild(params: OfflineGuildParams): Promise<any> {
        return axios.post('/gpbusiness/guild/offline', params);
    },

    getMemberList(params: GetMemberListParams): Promise<any> {
        return axios.post('/gpbusiness/guild/getMemberList', params);
    },

    getApplyList(params: GetApplyListParams): Promise<any> {
        return axios.post('/gpbusiness/guild/getApplyList', params);
    },

    getMemberLog(params: GetMemberLogParams): Promise<any> {
        return axios.post('/gpbusiness/guild/getMemberLog', params);
    },

    getMemberDetail(params: GetMemberDetailParams): Promise<any> {
        return axios.post('/gpbusiness/guild/getMemberDetail', params);
    },

    memberAudit(params: MemberAuditParams): Promise<any> {
        return axios.post('/gpbusiness/guild/memberAudit', params);
    },

    memberRemove(params: MemberRemoveParams): Promise<any> {
        return axios.post('/gpbusiness/guild/memberRemove', params);
    },

    addGuildInfo(params: AddGuildInfoParams): Promise<any> {
        return axios.post('/gpbusiness/guild/addGuildInfo', params);
    },

    getOrderList(params: GetOrderListParams): Promise<any> {
        return axios.post('/gpbusiness/guild/getOrderList', params);
    },
    getOrderListCsv(params: any): void {
        const url = new URL('/gpbusiness/guild/getOrderListCsv', window.location.href);
        url.search = (new URLSearchParams(params)).toString();
        window.location.href = url && url.toString();
    },
    getRewardList(params: GetRewardListParams): Promise<any> {
        return axios.post('/gpbusiness/guild/getRewardList', params);
    },
    getRewardListCsv(params: any): void {
        const url = new URL('/gpbusiness/guild/getRewardListCsv', window.location.href);
        url.search = (new URLSearchParams(params)).toString();
        window.location.href = url && url.toString();
    },
    // 账单管理
    getBillList(params: GetBillListParams): Promise<any> {
        return axios.post('/gpbusiness/guild/getBillList', params);
    },
    getOrderSummary(params: GetOrderSummaryParams): Promise<any> {
        return axios.post('/gpbusiness/guild/getOrderSummary', params);
    },
    getRewardSummary(params: GetRewardsSummaryParams): Promise<any> {
        return axios.post('/gpbusiness/guild/getRewardSummary', params);
    },
    lockOrder(params: LockOrderParams): Promise<any> {
        return axios.post('/gpbusiness/guild/lockOrder', params);
    },
    getOrderListSummaryCsv(params: any): void {
        const url = new URL('/gpbusiness/guild/getOrderListSummaryCsv', window.location.href);
        url.search = (new URLSearchParams(params)).toString();
        window.location.href = url && url.toString();
    },
    getRewardListSummaryCsv(params: any): void {
        const url = new URL('/gpbusiness/guild/getRewardListSummaryCsv', window.location.href);
        url.search = (new URLSearchParams(params)).toString();
        window.location.href = url && url.toString();
    }
};


export interface GetGuildListParams {
    id?: string,
    name?: string,
    status?: Array<number>,
    page: number,
    size: number
}

interface GetGuildDetailParams {
    id: string
}

interface EditGuildParams {
    id: string,
    name: string,
    intro: string,
    logoUrl: string,
}

interface DeleteGuildParams {
    id: string
}

interface OfflineGuildParams {
    id: string
}

export interface GetMemberListParams {
    id: string,
    tiebaId?: string,
    nickName?: string,
    role?: number,
    page: number,
    size: number,
}

export interface GetApplyListParams {
    id: string,
    tiebaId?: string,
    nickName?: string,
    status?: number,
    page: number,
    size: number,
}

interface GetMemberLogParams {
    tiebaId: string,
    id: string,
    page: number,
    size: number,
}

interface GetMemberDetailParams {
    tiebaId: string,
    tab?: string,
    guildId?: string | number
}

interface MemberAuditParams {
    tiebaId: string,
    guildId: string,
    type: Number,
    role: Number,
    reason: string,
}

interface MemberRemoveParams {
    tiebaId: string,
    guildId: string,
    reason: string,
}

interface AddGuildInfoParams {
    guildName: string,
    intro: string,
    logoUrl: string,
    establishTime: string,
    estimatePartnerCount: number,
    turnover: string,
    turnoverCret: string,
}

export interface GetOrderListParams {
    guildId: string,
    godNickName?: string,
    orderId?: string,
    bossNickName?: string,
    orderStatus?: number,
    payStartTime?: string,
    payEndTime?: string,
    page: number,
    size: number,
}

export interface GetRewardListParams {
    guildId: string,
    godNickName?: string,
    godTiebaId?: string,
    startDate?: string,
    endDate?: string,
    page: number,
    size: number
}

// 对账管理
export interface GetBillListParams {
    guildId: string
}

export interface GetOrderSummaryParams {
    guildId: string,
    date: string
}

export interface GetRewardsSummaryParams {
    guildId: string,
    date: string
}

export interface LockOrderParams {
    guildId: string,
    month: string,
    type: number
}
export default guildApi;