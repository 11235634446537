export const MOBILE_PLATFORM = {
    1: 'Android',
    2: 'iOS',
    3: 'Android、iOS'
};

export const PLATFORM = {
    1: 'PC',
    2: 'Switch',
    3: 'PlayStation',
    4: 'XBox',
    5: 'Android',
    6: 'iOS'
};

export const PLATFORM_CLIENT = {
    1: 'PC',
    2: 'Switch',
    3: 'PlayStation',
    4: 'XBox'
};

export const PLATFORM_MOBILE = {
    5: 'Android',
    6: 'iOS'
};

export const LANGUAGE = {
    1: '简体中文',
    3: '繁体中文',
    4: '英语',
    5: '日语',
    6: '韩语',
    7: '其他'
};

export const CATEGORY = {
    1: '手游',
    2: '端游',
    3: '页游'
};

export const APP_STATUS = {
    '-1': '未提交',
    0: '待审核',
    1: '审核中',
    2: '审核未通过',
    3: '已上线',
    4: '已下线',
    5: '审核通过（待上线）'
};

export const APP_STATUS_COLOR = {
    '-1': 'default',
    0: 'processing',
    1: 'warning',
    2: 'error',
    3: 'success',
    4: 'error',
    5: 'success'
};

export const PLAYER_NUM = {
    1: '单人',
    2: '多人'
};


export const DISK = {
    0: '无光盘',
    1: '有光盘'
};

export const CONTROLLER = {
    0: '不支持',
    1: '部分支持',
    2: '完全支持'
};

export const SWITCH_CARD = {
    0: '无卡带',
    1: '有卡带'
};

export const STEAM_ACHIEVEMENT = {
    0: '不支持',
    1: '支持'
};

export const SYSTEM = {
    1: 'Windows',
    2: 'MacOS',
    3: 'Linux'
};

export const APP_STATUS_TEXT = {
    statusOfficialChina: '官网（国服）',
    statusOfficialAmerican: '官网（美服）',
    statusOfficialHongKong: '官网（港服）',
    statusOfficialJapan: '官网（日服）',
    statusSteamChina: 'Steam（国服）',
    statusSteamAmerican: 'Steam（美服）',
    statusSteamHongKong: 'Steam（港服）',
    statusSteamJapan: 'Steam（日服）',
    statusWeGameChina: 'WeGame（国服）',
    statusWeGameAmerican: 'WeGame（美服）',
    statusWeGameHongKong: 'WeGame（港服）',
    statusWeGameJapan: 'WeGame（日服）',
    statusSwitchChina: 'Switch（国服）',
    statusSwitchAmerican: 'Switch（美服）',
    statusSwitchHongKong: 'Switch（港服）',
    statusSwitchJapan: 'Switch（日服）',
    statusPlayStationChina: 'PlayStation（国服）',
    statusPlayStationAmerican: 'PlayStation（美服）',
    statusPlayStationHongKong: 'PlayStation（港服）',
    statusPlayStationJapan: 'PlayStation（日服）',
    statusXboxChina: 'XBox（国服）',
    statusXboxAmerican: 'XBox（美服）',
    statusXboxHongKong: 'XBox（港服）',
    statusXboxJapan: 'XBox（日服）',
    statusIosChina: 'iOS',
    statusAndroidChina: 'Android',
    statusJointAndroid: '联运游戏包'
};

export const SERVICE_AREA = {
    1: '国服',
    2: '港服',
    3: '美服',
    4: '日服'
};

export const CHANNEL = {
    1: '官网',
    2: 'Steam',
    3: 'WeGame',
    4: 'Switch',
    5: 'PlayStation',
    6: 'XBox'
};

export const SERVICE_AREA_EN = {
    1: 'China',
    2: 'HongKong',
    3: 'American',
    4: 'Japan'
};

export const CHANNEL_EN = {
    1: 'Official',
    2: 'Steam',
    3: 'WeGame',
    4: 'Switch',
    5: 'PlayStation',
    6: 'Xbox'
};

export const GAME_TYPE = {
    1: '网游',
    2: '单机'
};

export const AGENT_MEMBER = {
    0: '待审核',
    // 1: '审核通过',
    2: '已驳回'
};

export const DYNAMIC_TYPE = {
    1: '公告',
    2: '更新',
    3: '赛事',
    4: '活动',
    5: '礼包'
};

export const DYNAMIC_STATUS = {
    '-1': '草稿',
    0: '待审核',
    1: '审核中',
    3: '审核未通过',
    4: '修改待审核',
    5: '已发表',
    6: '已下线',
    8: '修改未通过',
    9: '修改审核中'
};

export const LEVELS = {
    1: '青铜',
    2: '白银',
    3: '黄金',
    4: '钻石'
};

export const UNIT = {
    1: 'MB',
    2: 'GB'
};

export const PUBLISH_TYPE = {
    1: '审核通过后立即发布',
    2: '定时发布'
};

export const GAME_FEATURE = {
    1: '编辑推荐',
    2: '新游首发',
    3: '还原经典',
    4: '正版授权',
    5: '开放世界',
    6: '3A大作',
    7: '版本更新'
};

export const WIKI_STATUS = {
    '-1': '草稿',
    0: '待审核',
    1: '审核中',
    3: '审核未通过',
    4: '修改待审核',
    5: '已发表',
    6: '已下线',
    8: '修改未通过',
    9: '修改审核中'
};

export const WIKI_STATUS_COLOR = {
    '-1': 'default',
    0: 'default',
    1: 'processing',
    3: 'error',
    4: 'processing',
    5: 'success',
    6: 'error',
    8: 'error',
    9: 'processing'
};

export const GIFT_PLATFORM_TYPE = {
    7: 'Android',
    8: 'IOS'
};

// 礼包相关
export const GIFT_STATUS = {
    1: '待审核',
    2: '审核通过',
    3: '审核未通过',
    10: '待上线',
    20: '已上线',
    30: '已下线'
};

export const GIFT_STATUS_MAP = {
    WAIT_AUDIT: 1,
    AUDIT_PASS: 2,
    AUDIT_FAIL: 3,
    WAIT_ONLINE: 10,
    ONLINE: 20,
    OFFLINE: 30
};

export const GIFT_PLATFORM_CLIENT = {
    1: '官网',
    2: 'Steam',
    3: 'WeGame',
    4: 'Switch',
    5: 'PlayStation',
    6: 'Xbox'
};


export const GIFT_TYPE = {
    1: '首发礼包',
    2: '日常礼包',
    3: '节日礼包',
    4: '预约礼包'
};

export const GIFT_PLATFORM_NEW = {
    1: 'Android'
};