import {HeaderMenu} from 'routes/universialConfig';

const menu: HeaderMenu = {
    key: 'agentOperator',
    title: '选择账号类型',
    defaultPath: '/agentOperator',
    subs: [{
        key: 'agentOperatorHome',
        title: '选择账号类型',
        component: 'AgentOperator',
        path: '/agentOperator/home'
    }, {
        key: 'certificationPartner',
        title: '认证成为内容合作商',
        component: 'CertificationPartner',
        path: '/agentOperator/certificationPartner'
    }, {
        key: 'certificationGuild',
        title: '认证成为游戏公会',
        component: 'CertificationGuild',
        path: '/agentOperator/certificationGuild'
    }]
};

export default menu;