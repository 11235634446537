import React from 'react';
import classnames from 'classnames';
import {Link, useLocation, useHistory} from 'react-router-dom';
import {Layout, Menu, Skeleton} from 'antd';
import Header from '../components/Header';
import SearchInput from './components/SearchInput';
import {usePersistFn, useRequest} from '@baidu/gp-hooks';
import docApi from 'api/doc';
import styles from './style.module.less';

const {SubMenu} = Menu;
const {Sider, Content} = Layout;

const iterMenu = (menu: any) => (
    'children' in menu
        ? (
            <SubMenu
                key={menu.id}
                title={menu.title}
            >
                {menu.children.map(iterMenu)}
            </SubMenu>
        )
        : (
            <Menu.Item key={menu.id}>
                <Link to={`/doc/${menu.id}`} title={menu.title}>{menu.title}</Link>
            </Menu.Item>
        )
);

const DocLayout: React.FC = ({children}) => {
    const [_selectedKeys, setSelectedKeys] = React.useState<string[]>([]);
    const [_openKeys, setOpenKeys] = React.useState<string []>();

    const history = useHistory();
    const redirect = usePersistFn(() => {
        const {listData} = data;
        if (listData.length === 0) return;
        const menu = listData.find((item: any) => item.children.length !== 0);
        if (!menu) return;
        const defaultDoc = menu.children[0].id;
        history.push(`/doc/${defaultDoc}`);
    });

    const {data, loading} = useRequest(docApi.getList);

    const {pathname} = useLocation();
    React.useEffect(() => {
        if (!data) return;
        const find = /\/doc\/(\d+)/.exec(pathname);
        if (!find) return redirect();
        const docId = find[1];
        const menu = data.listData.find((item: any) => item.children.find((i: any) => i.id === docId));
        if (!menu) return redirect();
        setSelectedKeys([menu.id, docId]);
        setOpenKeys([menu.id, docId]);
    }, [pathname, data, redirect]);

    return (
        <Layout className={styles.layoutWrapper}>
            <Header />
            <Layout className={classnames(
                styles.mainWrapper
            )}>
                <Skeleton loading={loading}>
                    <Sider
                        className={styles.sider}
                    >
                        <SearchInput doc={data?.listData || []}/>
                        <Menu
                            theme="light"
                            mode="inline"
                            selectedKeys={_selectedKeys}
                            openKeys={_openKeys}
                            onOpenChange={key => {
                                setOpenKeys(key.map(item => item.toString()));
                            }}
                        >
                            {(data?.listData || []).map(iterMenu)}
                        </Menu>
                    </Sider>
                    <Content className={styles.contentWrapper}>
                        <div style={{minWidth: 1080}}>
                            {children}
                        </div>
                    </Content>
                </Skeleton>
            </Layout>
        </Layout>
    );
};

export default DocLayout;
