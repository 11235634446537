import axios from 'plugins/axios';

const gameManageApi = {
    getUniversalAppList(params: {gameName?: string}): Promise<any> {
        return axios.post('/gpbusiness/universalapp/getUniversalAppList', params);
    },
    createUniversalApp(params: GameCreationParams): Promise<any> {
        return axios.post('/gpbusiness/universalapp/createUniversalApp', params);
    },
    getUniversalInfo(params: GameGetDataParams): Promise<any> {
        return axios.post('/gpbusiness/universalapp/getUniversalInfo', params);
    },
    addUniversalInfo(params: any): Promise<any> {
        return axios.post('/gpbusiness/universalapp/addUniversalInfo', params);
    },
    getGameHomePageInfo(params: GameGetDataParams): Promise<any> {
        return axios.post('/gpbusiness/universalapp/getGameHomePageInfo', params);
    },
    getTodoInfo(params: GameGetDataParams): Promise<any> {
        return axios.post('/gpbusiness/universalapp/getTodoInfo', params);
    },
    getPlatformConf(params: {appId: string, system: string}): Promise<any> {
        return axios.post('/gpbusiness/universalapp/getPlatformConf', params);
    },
    setPlatformConf(params: any): Promise<any> {
        return axios.post('/gpbusiness/universalapp/setPlatformConf', params);
    },
    getExtralInfo(params: GameGetDataParams): Promise<any> {
        return axios.post('/gpbusiness/universalapp/getExtralInfo', params);
    },
    getHistoryVersion(params: GameGetDataParams): Promise<any> {
        return axios.post('/gpbusiness/universalapp/getHistoryVersion', params);
    },
    addCooperationMode(params: {appId: string, isBrandMode: number}): Promise<any> {
        return axios.post('/gpbusiness/universalapp/addCooperationMode', params);
    },
    getCooperationType(params: GameGetDataParams): Promise<any> {
        return axios.post('/gpbusiness/universalapp/getCooperationType', params);
    },
    getAppList(params: GameListParams): Promise<any> {
        return axios.post('/gpbusiness/universalapp/getAppList', params);
    },
    getRejectReasons(params: GameGetDataParams): Promise<any> {
        return axios.post('/gpbusiness/universalapp/getRejectReasons', params);
    },
    getJoinStep(params: GameGetDataParams): Promise<any> {
        return axios.post('/gpbusiness/officialApp/getJoinStep', params);
    },
    getSimilarAppList(params: GameListParams): Promise<any> {
        return axios.post('/gpbusiness/universalapp/getSimilarAppList', params);
    },
    getSimilarListByAppId(params: GameListAppIdParams): Promise<any> {
        return axios.post('/gpbusiness/universalapp/getSimilarListByAppId', params);
    },
    getSimilarAppDetail(params: GameDetailParams): Promise<any> {
        return axios.post('/gpbusiness/universalapp/getSimilarAppDetail', params);
    },
    getSimilarDetailByAppId(params: GameDetailParams): Promise<any> {
        return axios.post('/gpbusiness/universalapp/getSimilarDetailByAppId', params);
    },
    setClaimGameInfo(params: GameClaimInfoParams): Promise<any> {
        return axios.post('/gpbusiness/universalapp/setClaimGameInfo', params);
    },
    // api方式上传
    getApiConfInfo(params: any): Promise<any> {
        return axios.post('/gpbusiness/api/getApiConfInfo', params);
    },
    addApiConfInfo(params: any): Promise<any> {
        return axios.post('/gpbusiness/api/addApiConfInfo', params);
    },
    modifyApiConfInfo(params: any): Promise<any> {
        return axios.post('/gpbusiness/api/modifyApiConfInfo', params);
    },
    getApiInterfaceDocument(): Promise<any> {
        return axios.post('/gpbusiness/api/getApiInterfaceDocument');
    },
    getGiftList(params: GiftListParams): Promise<any> {
        return axios.post('/gpbusiness/gift/getList', params);
    },
    getGiftDetail(params: GiftDetailParams): Promise<any> {
        return axios.post('/gpbusiness/gift/getDetail', params);
    },
    addGift(params: any): Promise<any> {
        return axios.post('/gpbusiness/gift/add', params);
    },
    updateGift(params: any): Promise<any> {
        return axios.post('/gpbusiness/gift/update', params);
    },
    giftGameInfo(params: GiftGameInfoParams): Promise<any> {
        return axios.post('/gpbusiness/gift/queryBusinessGame', params);
    }
};

interface GameCreationParams {
    gameId: string
    gameName: string
    gameType: number
}

interface GameGetDataParams {
    appId: string
}

interface GameListParams {
    page: number
    size: number
    gameName?: string
}

interface GameDetailParams {
    gameId: string
    gameName: string
}

interface GameListAppIdParams {
    page: number
    size: number
    appId?: string
}

interface GameClaimInfoParams {
    appId?: string
    gameId?: string
    claimInfo?: string
}

export interface GiftListParams{
    normalizedStatus?: string,
    name?: string,
    page: number,
    size: number,
    appId: string,
    businessType: number
}

export interface GiftDetailParams{
    appId: string,
    id: string
}

export interface GiftGameInfoParams {
    appId: string
    gameName: string
};

export default gameManageApi;