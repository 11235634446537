const config = {
    siteName: '百度游戏开放平台',
    // eslint-disable-next-line max-len
    logo: 'https://gameplus-platform.cdn.bcebos.com/gameplus-platform/upload/file/source/95616bd0bdce8c9e6f8618d9ba9d775a.png',
    layouts: [
        {
            name: 'index',
            include: [/\/index/]
        },
        {
            name: 'doc',
            include: [/\/doc/]
        },
        {
            name: 'public',
            include: [
                /\/message/,
                /\/certification/,
                /\/brand/,
                /\/universialGame\/creation/,
                /\/cooperationMode\/*/,
                /\/agentOperator\/*/,
                /\/supplyInfoSubmit\/*/
            ],
            exclude: [
                /\/profile/,
                /\/universialGame\/brandManage/,
                /\/universialGame\/brandPro/
            ]
        },
        {
            name: 'primary',
            include: [/.*/],
            exclude: [/\/forbidden/]
        }
    ],
    BOS_CONFIG: {
        url: 'https://gp-dev.cdn.bcebos.com',
        // url: 'https://gameplus-platform.cdn.bcebos.com',
        bucket: 'gp-dev'
    }
};

export default config;