import {AxiosRequestConfig, AxiosError, AxiosResponse} from 'axios';
import {message} from 'antd';

export const AXIOS_DEFAULT_CONFIG = {
    timeout: 30000,
    withCredentials: true
};

export function requestSuccessFunc(request: AxiosRequestConfig) {
    const url = request.url;
    if (!url) {
        return request;
    }

    if (process.env.NODE_ENV !== 'development') {
        //  TODO: 测试环境时使用这部分（服务端完成线下环境接口转发后可删除这部分）
        // const needChangeOriginUrl = [
        //     '/gpbusiness/finance/scopeInfo',
        //     '/gpbusiness/finance/getBankBranchList',
        //     '/gpbusiness/finance/getBankList',
        //     '/gpbusiness/finance/getCityListByProvince',
        //     '/gpbusiness/finance/getProvinceList'
        // ];
        // if (needChangeOriginUrl.includes(url)) {
        //     request.url = `http://10.138.43.34:8091${url}`;
        // } else {
        //     const _url = new URL(url, document.baseURI);
        //     const params = new URLSearchParams(_url.search.slice(1));
        //     const reqId = `${new Date().valueOf()}_${Math.floor(Math.random() * 100)}`;
        //     params.set('reqid', reqId);
        //     request.url = _url.href;
        // }
        // 上线时使用这部分
        const _url = new URL(url, document.baseURI);
        const params = new URLSearchParams(_url.search.slice(1));
        const reqId = `${new Date().valueOf()}_${Math.floor(Math.random() * 100)}`;
        params.set('reqid', reqId);
        request.url = _url.href;
    }

    return request;
}

export function requestFailFunc(requestError: AxiosError) {
    return Promise.reject(requestError.response);
}

export function responseSuccessFunc(response: AxiosResponse) {
    const {status, error, data} = response.data;
    switch (status) {
        case 200:
            return data;
        case 401:
            if (response.config.url?.endsWith('/gpbusiness/user/getuserinfo')) {
                return 'UNAUTHORIZED';
            }
            window.location.replace('/gpbusiness');
            break;
        default:
            message.error(error.message);
            return Promise.reject(error.message);
    }
}

export function responseFailFunc(responseError: AxiosError) {
    return Promise.reject(responseError.response);
}
