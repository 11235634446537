import React, {FC, Fragment, useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Popover, Space, Image, Badge, Tooltip, Input, Button} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import classnames from 'classnames';
import GameInfoContext from 'context/GameInfoContext';
import UserContext from 'context/UserContext';
import TagIcon1 from 'assets/privilege/level_tag1.png';
import TagIcon2 from 'assets/privilege/level_tag2.png';
import TagIcon3 from 'assets/privilege/level_tag3.png';
import TagIcon4 from 'assets/privilege/level_tag4.png';
import IconFont from 'components/IconFont';
import EmptyComponent from 'components/EmptyComponent';
import {APP_STATUS, APP_STATUS_COLOR} from 'pages/UniversialGame/consts';
import Styles from './style.module.less';
interface Props {
    collapsed: boolean,
    getGameName: (name: string) => void
}

const TagIconMap = {
    1: TagIcon1,
    2: TagIcon2,
    3: TagIcon3,
    4: TagIcon4
};

const GameSwitch: FC<Props> = ({collapsed, getGameName}) => {
    const {userInfo, isDriver} = useContext(UserContext);
    const [visible, setVisible] = useState<boolean>(false);
    const [curApp, setCurApp] = useState<any>();
    const {appId, setAppId, universialAppList, getUniversialAppList} = useContext(GameInfoContext);

    useEffect(() => {
        if (appId) {
            const appInfo = universialAppList.find(item => item.appId === appId);
            setCurApp(appInfo || universialAppList[0]);
            return;
        }
        setCurApp(universialAppList[0]);
    }, [appId, universialAppList]);

    return (
        <Popover
            placement="bottomLeft"
            trigger="click"
            visible={visible}
            content={
                <GameListRender
                    list={universialAppList}
                    curApp={curApp}
                    setAppId={setAppId}
                    setVisible={setVisible}
                    setCurApp={setCurApp}
                    getUniversialAppList={(params?: {
                        gameName: string;
                    } | undefined) => {
                        getGameName(params?.gameName || '');
                        getUniversialAppList(params);
                    }}
                />
            }
            overlayClassName={Styles.popover}
            onVisibleChange={(visible: boolean) => {
                if(userInfo?.newGameFirst === 1 && isDriver === true) {
                    return;
                }
                setVisible(visible);
            }}
        >
            {collapsed ? (
                <Tooltip
                    placement="right"
                    title={
                        <Space direction="vertical">
                            <span>{curApp?.gameName}</span>
                            <span>appId：{curApp?.appId}</span>
                        </Space>
                    }
                >
                    <div
                        className={Styles.circle}
                        onClick={() => {
                            if(userInfo?.newGameFirst === 1 && isDriver === true) {
                                return;
                            }
                            universialAppList.length > 1 && setVisible(!visible);
                        }}
                    >
                        <Image src={curApp?.icon} width={38} height={38} preview={false}/>
                    </div>
                </Tooltip>
            ) : (
                <Tooltip
                    placement="bottom"
                    title={(userInfo?.newGameFirst === 1 && isDriver === true) ? '' : `appId：${curApp?.appId}`}
                >
                    <div
                        id="changeGame"
                        className={Styles.rect}
                        onClick={() => {
                            if(userInfo?.newGameFirst === 1 && isDriver === true) {
                                return;
                            }
                            universialAppList.length > 1 && setVisible(!visible);
                        }}
                    >
                        <span className={Styles.gameName}>{curApp?.gameName}</span>
                        {universialAppList.length > 1 && (
                            <IconFont name="ic_switch" style={{fontSize: '12px', marginLeft: '5px'}}/>)
                        }
                    </div>
                </Tooltip>
            )
            }
        </Popover>
    );
};

const GameListRender = ({list, curApp, setAppId, setVisible, setCurApp, getUniversialAppList}: {
    list: any,
    curApp: any,
    setAppId: (id: string) => void,
    setVisible: (visible: boolean) => void,
    setCurApp: (app: any) => void,
    getUniversialAppList: (params?: {gameName: string}) => void,
}) => {
    const history = useHistory();
    const [inputRef, setInputRef] = React.useState<Input | null>();

    const onSearchAppList = () => {
        getUniversialAppList({gameName: inputRef?.state.value || ''});
    };
    const onSearchChange = (event: any) => {
        if(event?.type === 'click') {
            getUniversialAppList();
        }
    };

    return (
        <Fragment>
            <Input
                ref={setInputRef}
                placeholder="搜索游戏名"
                className={Styles.search}
                allowClear
                suffix={
                    <SearchOutlined
                        style={{fontSize: '16px', color: '#9c9c9c'}}
                        onClick={onSearchAppList}
                    />
                }
                onPressEnter={onSearchAppList}
                onChange={onSearchChange}
            />
            <Space direction="vertical" size={16} className={Styles.gameList}>
                {list.length > 0 && list.map((item: any) => (
                    <div
                        key={item.appId}
                        className={classnames(Styles.gameItem, curApp?.appId === item.appId ? Styles.active : '')}
                        onClick={() => {
                            setAppId(item.appId);
                            setVisible(false);
                            setCurApp(item);
                            if(curApp.appId !== item.appId) {
                                history.push('/universialGame/gameHome');
                            }
                        }}
                    >
                        <Image
                            src={item.icon}
                            width={68}
                            preview={false}
                            style={{borderRadius: '16px'}}
                            height={+item.iconType === 1 ? 68 : 90}
                        />
                        <Space direction="vertical" size={1}>
                            <div className={Styles.gameNameBox}>
                                <span className={Styles.gameName}>{item.gameName}</span>
                                {!!item?.isBrandMode && !!item?.currentLevel && (
                                    <Image
                                        src={TagIconMap[item?.currentLevel]}
                                        preview={false}
                                        width={67}
                                        height={18}
                                    />
                                )}
                            </div>
                            <div className={Styles.mode}>
                                合作模式：
                                {!!item.isJointMode && '联运 '}
                                {!!item.isBrandMode && '品牌'}
                                {!item.isJointMode && !item.isBrandMode && '未合作'}
                            </div>
                            {+item.status === -2
                                ? (
                                    <Button type="link" onClick={() => {
                                        history.push('/universialGame/gameHome');
                                    }}>进入游戏首页查看状态</Button>
                                )
                                : item.status === 3 && !item.isBrandMode && !item.isJointMode
                                    ? (
                                        <Badge
                                            text="请选择合作模式"
                                            status='warning'
                                            style={{fontSize: '12px'}}
                                        />
                                    )
                                    : item.status === 3 && (item.isBrandMode || item.isJointMode) && !item.hasPkg
                                        ? (
                                            <Badge
                                                text="请完善合作信息"
                                                status='warning'
                                                style={{fontSize: '12px'}}
                                            />
                                        )
                                        : (
                                            <Badge
                                                text={APP_STATUS[item.status]}
                                                status={APP_STATUS_COLOR[item.status]}
                                                style={{fontSize: '12px'}}
                                            />
                                        )}
                        </Space>
                    </div>
                ))}
                {list.length === 0 && <EmptyComponent />}
            </Space>
        </Fragment>
    );
};

export default GameSwitch;