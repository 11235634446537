import React from 'react';
import {useRequest} from '@baidu/gp-hooks';
import messageApi from 'api/message';
import UserContext from 'context/UserContext';

const MessageContext = React.createContext(
    {
        data: {
            sysNotice: 0,
            gameNotice: 0,
            activeNotice: 0
        },
        refresh: () => {}
    } as {
    data: {
        sysNotice: number,
        gameNotice: number,
        activeNotice: number
    },
    refresh: () => void
});

export const MessageProvider: React.FC = ({children}) => {
    const {hasLogin, statusInfo} = React.useContext(UserContext);
    const {data, refresh} = useRequest(messageApi.getUnreadMsgCount, {
        ready: hasLogin && !!(statusInfo?.hasCertification),
        initialData: {
            sysNotice: 0,
            gameNotice: 0,
            activeNotice: 0
        }
    });

    return (
        <MessageContext.Provider value={{
            data,
            refresh
        }}>
            {children}
        </MessageContext.Provider>
    );
};

export default MessageContext;